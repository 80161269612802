import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface AccountReferral {
  errors: unknown;
  Account: AccountReferral;
  isAuthenticated: boolean;
}
@Module
export default class AccountReffeReferralModule
  extends VuexModule
  implements AccountReferral
{
  errors = {};
  Account = {} as AccountReferral;
  isAuthenticated = !!JwtService.getToken();

  get getAccountReferral() {
    return this.referralData;
  }

  @Mutation
  [Mutations.REFERRAL_DATA](AccountPayment) {
    this.referralData = AccountPayment;
  }

  @Action
  [Actions.REFERRAL]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getData("/account/referral")
        .then(({ data }) => {
          this.context.commit(Mutations.REFERRAL_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
