import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Ticket {
  errors: unknown;
  Ticket: Ticket;
  isAuthenticated: boolean;
}

@Module
export default class TicketModule extends VuexModule implements Ticket {
  errors = {};
  Ticket = {} as Ticket;
  listTeamData = [];
  AMInfo = {
    full_name: "",
    zalo_account: "3471248515551622842",
    skype_account: "omeezvn",
    phone_number: "02432181488",
    gmail_account: "publisher@omeez.vn",
    avatar_url:
      "https://dev1-pub-api-service.omee.vn/img/manager/avatar-default.png",
  };
  isAuthenticated = !!JwtService.getToken();

  get getTicketData() {
    return this.Ticket;
  }
  get getAMInfo() {
    return this.AMInfo;
  }
  get getlistTeamData() {
    return this.listTeamData;
  }
  get getAttachmentCreateData() {
    return this.attachmentCreateData;
  }

  @Mutation
  [Mutations.TICKET_DATA](Ticket) {
    this.Ticket = Ticket;
  }

  @Mutation
  [Mutations.LIST_TEAM_DATA](data) {
    this.listTeamData = data;
  }

  @Mutation
  [Mutations.AM_SUPPORT_INFO](data) {
    this.AMInfo = data;
  }

  @Mutation
  [Mutations.ATTACHMENT_CREATE_DATA](attachmentCreateData) {
    this.attachmentCreateData = attachmentCreateData;
  }
  @Action
  [Actions.AM_SUPPORT_INFO](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/tickets/support-info", {})
        .then(({ data }) => {
          this.context.commit(Mutations.AM_SUPPORT_INFO, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.TICKET](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/tickets", { params: data })
        .then(({ data }) => {
          this.context.commit(Mutations.TICKET_DATA, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.LIST_TEAM](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/tickets/topics", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.LIST_TEAM_DATA, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.TICKET_POST](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("/tickets/create-ticket", data)
        .then(({ data }) => {
          const error = {};
          this.context.commit(Mutations.SET_ERROR, error);
          this.context.commit(Mutations.TICKET_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.TRANSACTION]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getData("/tickets/ticket/create-transaction")
        .then(({ data }) => {
          this.context.commit(Mutations.TRANSACTION_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.ATTACHMENT_CREATE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/tickets/ticket/create-attachment", data)
        .then(({ data }) => {
          this.context.commit(Mutations.ATTACHMENT_CREATE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.ATTACHMENT_DETELE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/tickets/ticket/delete-attachment", data)
        .then(({ data }) => {
          this.context.commit(Mutations.ATTACHMENT_DETELE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
