import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export default class MembershipGiftModule extends VuexModule {
  @Action
  [Actions.MEMBERSHIP_CATEGORY_LIST]() {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/gift-exchange/list-all-groups", {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.MEMBERSHIP_GIFT_LIST](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/gift-exchange", {
        params: data,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.MEMBERSHIP_GIFT_DETAILS](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("gift-exchange/" + data.id + "/detail", {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.MEMBERSHIP_GIFT_REDEMPTION_HISTORY](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/gift-exchange/histories", {
        params: data,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.MEMBERSHIP_REDEEM_GIFT](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post(
        "gift-exchange/" + data.id + "/confirm-gift-exchange",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
