import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface PayoutRevenue {
  errors: unknown;
  PayoutRevenue: PayoutRevenue;
  isAuthenticated: boolean;
}

@Module
export default class PayoutRevenueModule
  extends VuexModule
  implements PayoutRevenue
{
  errors = {};
  PayoutRevenue = {} as PayoutRevenue;
  isAuthenticated = !!JwtService.getToken();

  get getPayoutRevenueData() {
    return this.PayoutRevenue;
  }

  get getPayoutRevenueDetailData() {
    return this.detailPayoutRevenueData;
  }

  @Mutation
  [Mutations.PAYOUT_REVENUE_DATA](PayoutRevenue) {
    this.PayoutRevenue = PayoutRevenue;
  }

  @Mutation
  [Mutations.DETAIL_PAYOUT_REVENUE_DATA](detailPayoutRevenueData) {
    this.detailPayoutRevenueData = detailPayoutRevenueData;
  }

  @Action
  [Actions.PAYOUT_REVENUE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/payout/revenue", data)
        .then(({ data }) => {
          this.context.commit(Mutations.PAYOUT_REVENUE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DETAIL_PAYOUT_REVENUE](dataSearch) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/payout/revenue/detail", dataSearch)
        .then(({ data }) => {
          this.context.commit(Mutations.DETAIL_PAYOUT_REVENUE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
