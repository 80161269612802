import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { number } from "@intlify/core-base";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CommunityReportDetai {
  first_name: string;
  last_name: string;
  avatar: string;
  facebook: string;
  introduce: string;
  intagram: string;
  tiktok: string;
  referral_publisher_for_viewer: {
    id: number;
    referral_publisher_id: number;
    publisher_id: number;
    status: number;
    status_obj: {
      id: number;
      name: number;
    };
  };
  referral_code: string;
  total_referral_publishers: {
    referral_publisher_id: number;
    count_member: number;
  };
  earned_money: {
    referral_publisher_id: number;
    count_member: number;
  };
}

export interface CommunityReportDetaiInfo {
  errors: unknown;
  CommunityReportDetai: CommunityReportDetai;
  isAuthenticated: boolean;
}

@Module
export default class CommunityReportDetailModule
  extends VuexModule
  implements CommunityReportDetaiInfo
{
  errors = {};
  CommunityReportDetai = {} as CommunityReportDetai;
  isAuthenticated = !!JwtService.getToken();

  get getCommunityReportDetaiData(): CommunityReportDetai {
    return this.CommunityReportDetai;
  }
  get getCommunityReportChartDetaiData() {
    return this.communityReportDetailChartData;
  }
  get getReportListDropdowmData() {
    return this.reportListDropdowmData;
  }
  get getDashboardRevenueData() {
    return this.dashboardRevenueData;
  }

  @Mutation
  [Mutations.COMMUNITY_REPORT_DETAIL_DATA](CommunityReportDetai) {
    this.CommunityReportDetai = CommunityReportDetai;
  }
  @Mutation
  [Mutations.COMMUNITY_REPORT_DETAIL_CHART_DATA](CommunityReportDetaiChart) {
    this.communityReportDetailChartData = CommunityReportDetaiChart;
  }

  @Mutation
  [Mutations.REPORT_LIST_DROPDOWN_DATA](reportListDropdowmData) {
    this.reportListDropdowmData = reportListDropdowmData;
  }

  @Mutation
  [Mutations.DASHBOARD_REVENUE_DATA](dashboardRevenueData) {
    this.dashboardRevenueData = dashboardRevenueData;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.COMMUNITY_REPORT_DETAIL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/community/detail/revenue-detail", data)
        .then(({ data }) => {
          this.context.commit(Mutations.COMMUNITY_REPORT_DETAIL_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.COMMUNITY_REPORT_DETAIL_CHART](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/community/detail/revenue", data)
        .then(({ data }) => {
          this.context.commit(
            Mutations.COMMUNITY_REPORT_DETAIL_CHART_DATA,
            data
          );
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.COMMUNITY_REPORT_DETAIL_SEARCH](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/community/report/list-dropdown", data)
        .then(({ data }) => {
          this.context.commit(Mutations.COMMUNITY_REPORT_DETAIL_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.REPORT_LIST_DROPDOWN](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/report/list-dropdown", data)
        .then(({ data }) => {
          this.context.commit(Mutations.REPORT_LIST_DROPDOWN_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DASHBOARD_REVENUE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post(
        "/community/detail/chart-conversion-dashboard",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.DASHBOARD_REVENUE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
