import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface PayoutHistory {
  errors: unknown;
  PayoutHistory: PayoutHistory;
  isAuthenticated: boolean;
}

@Module
export default class PayoutHistoryModule
  extends VuexModule
  implements PayoutHistory
{
  errors = {};
  PayoutHistory = {} as PayoutHistory;
  isAuthenticated = !!JwtService.getToken();

  get getPayoutHistoryData() {
    return this.PayoutHistory;
  }

  get getPayoutHistoryDetailData() {
    return this.payoutHistoryDetailData;
  }

  @Mutation
  [Mutations.PAYOUT_HISTORY_DATA](PayoutHistory) {
    this.PayoutHistory = PayoutHistory;
  }

  @Mutation
  [Mutations.PAYOUT_HISTORY_DETAIL_DATA](payoutHistoryDetailData) {
    this.payoutHistoryDetailData = payoutHistoryDetailData;
  }

  @Action
  [Actions.PAYOUT_HISTORY](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/payout/list", data)
        .then(({ data }) => {
          this.context.commit(Mutations.PAYOUT_HISTORY_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.PAYOUT_HISTORY_DETAIL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getDataById("/payout/detail", data.id, data)
        .then(({ data }) => {
          this.context.commit(Mutations.PAYOUT_HISTORY_DETAIL_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
