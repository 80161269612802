import axios from "axios";
import router from "@/router";
const setup = () => {
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (err.response.status === 401 && !originalConfig._retry) {
        router.push({ name: "sign-in" });
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
