import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export default class CouponModule extends VuexModule {
  @Action
  [Actions.COUPON_CONFIG_DATA](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/coupon/list-all-groups", { params: data })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.COUPON_PUBLIC_LIST](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      const params = JSON.parse(JSON.stringify(data));
      delete params.cancelToken;
      return ApiService.query("/coupon/public", {
        params: params,
        cancelToken: data.cancelToken,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.COUPON_SAVE_PUBLIC](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.post("/coupon/add-coupon-public", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.COUPON_SAVE_SHARE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.post("/coupon/add-coupon-code", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.COUPON_MY_COUPON](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      const params = JSON.parse(JSON.stringify(data));
      delete params.cancelToken;
      return await ApiService.query("/coupon/my-coupon", {
        params: data,
        cancelToken: data.cancelToken,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.COUPON_GET_DETAIL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/coupon/" + data.id + "/detail", {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.COUPON_HISTORIES_USE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("/coupon/get-histories-use-coupon", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.COUPON_CONFIRM_USE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.put(
        "/coupon/" + data.coupon_id + "/confirm-use-coupon-code",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
