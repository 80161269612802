import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Notification {
  errors: unknown;
  Notification: Notification;
  isAuthenticated: boolean;
}

@Module
export default class NotificationModule
  extends VuexModule
  implements Notification
{
  errors = {};
  Notification = {} as Notification;
  isAuthenticated = !!JwtService.getToken();
  topNews = [];
  get getNotificationData() {
    return this.Notification;
  }

  get getTopNewsData() {
    return this.topNews;
  }
  get getNotificationGroupsData() {
    return this.notificationGroupsData;
  }

  get getNotificationListSimpleData() {
    return this.notificationListSimpleData;
  }

  get getNotificationBannersData() {
    return this.notificationBannersData;
  }

  get getNotificationTotalUnreadData() {
    return this.notificationTotalUnreadData;
  }
  @Mutation
  [Mutations.TOP_NEWS_DATA](data) {
    data.setTime = new Date();
    this.topNews = data;
  }
  @Mutation
  [Mutations.NOTIFICATION_DATA](Notification) {
    this.Notification = Notification;
  }
  @Mutation
  [Mutations.NOTIFICATION_GROUPS_DATA](NotificationGroups) {
    this.notificationGroupsData = NotificationGroups;
  }

  @Mutation
  [Mutations.NOTIFICATION_LIST_SIMPLE_DATA](notificationListSimpleData) {
    this.notificationListSimpleData = notificationListSimpleData;
  }

  @Mutation
  [Mutations.NOTIFICATION_BANNERS_DATA](notificationBannersData) {
    this.notificationBannersData = notificationBannersData;
  }

  @Mutation
  [Mutations.NOTIFICATION_TOTAL_UNREAD_DATA](notificationTotalUnreadData) {
    this.notificationTotalUnreadData = notificationTotalUnreadData;
  }

  @Action
  [Actions.NOTIFICATION](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/notification", data)
        .then(({ data }) => {
          this.context.commit(Mutations.NOTIFICATION_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.NOTIFICATION_GROUPS](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/notification/groups", data)
        .then(({ data }) => {
          this.context.commit(Mutations.NOTIFICATION_GROUPS_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.NOTIFICATION_DELETE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/notification/delete", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.NOTIFICATION_LIST_SIMPLE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/notification/list-simple", data)
        .then(({ data }) => {
          this.context.commit(Mutations.NOTIFICATION_LIST_SIMPLE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.NOTIFICATION_BANNERS](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/banners", data)
        .then(({ data }) => {
          this.context.commit(Mutations.NOTIFICATION_BANNERS_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.NOTIFICATION_TOTAL_UNREAD](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/notification/total-unread", { params: data })
        .then(({ data }) => {
          this.context.commit(Mutations.NOTIFICATION_TOTAL_UNREAD_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.TOP_NEWS](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.post(
        "/notification/my-notification-by-type",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.TOP_NEWS_DATA, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
