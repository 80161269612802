import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { toastMsgError } from "@/core/helpers/toastMessage";
import EventBus from "vue3-eventbus";
import i18n from "@/core/plugins/i18n";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: ["auth"],
    },
    children: [
      {
        path: "/home",
        name: "home",
        redirect: "/dashboard",
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/update-info",
        name: "update-info",
        component: () => import("@/views/publisher/UpdateInfo.vue"),
        meta: {
          pageTitle: "Update Information",
          breadcrumbs: ["update_information"],
          pageHeading: "update_information",
        },
      },
      {
        path: "/report",
        name: "report",
        children: [
          {
            path: "/report/traffic",
            name: "report-traffic",
            component: () =>
              import("@/views/publisher/pages/report/clickTraffic.vue"),
            meta: {
              pageTitle: "Report Click - Traffic",
              breadcrumbs: ["report", "clickTraffic"],
              pageHeading: "clickTraffic",
            },
          },
          {
            path: "/report/convert",
            name: "report-convert",
            component: () =>
              import("@/views/publisher/pages/report/convert.vue"),
            meta: {
              pageTitle: "Report Convert",
              breadcrumbs: ["report", "convert"],
              pageHeading: "convert",
            },
          },
          {
            path: "/report/campaigns",
            name: "report-campaigns",
            component: () =>
              import("@/views/publisher/pages/report/campaigns.vue"),
            meta: {
              pageTitle: "Report Campaigns",
              breadcrumbs: ["report", "campaigns"],
              pageHeading: "campaigns",
            },
          },
          {
            path: "/report/customer-journey",
            name: "report-journey",
            component: () =>
              import("@/views/publisher/pages/report/journey.vue"),
            meta: {
              pageTitle: "Report Customer Journey",
              breadcrumbs: ["report", "journey"],
              pageHeading: "Report.customer_journey",
            },
          },
        ],
      },
      {
        path: "/campaigns",
        name: "campaigns-main",
        component: () =>
          import("@/views/publisher/pages/campaign/CampaignMain.vue"),
        children: [
          {
            path: "",
            name: "campaign-suggest",
            component: () =>
              import("@/views/publisher/pages/campaign/CampaignSuggest.vue"),
            meta: {
              pageTitle: "Campaign.suggest_for_you",
              breadcrumbs: [
                { path: "/campaigns", title: "campaigns" },
                { path: "/campaigns", title: "Campaign.suggest_for_you" },
              ],
              pageHeading: "Campaign.suggest_for_you",
            },
          },
          {
            path: ":group_cate",
            name: "campaign-category",
            component: () =>
              import("@/views/publisher/pages/campaign/CampaignList.vue"),
            meta: {
              pageTitle: "campaigns",
              breadcrumbs: [
                { path: "/campaigns", title: "campaigns" },
                { path: "", title: "industry" },
              ],
              pageHeading: "campaigns",
            },
          },
          {
            path: "group/:group_cate",
            name: "campaign-group",
            component: () =>
              import("@/views/publisher/pages/campaign/CampaignGroupList.vue"),
            meta: {
              pageTitle: "Campaign.group_campaign",
              breadcrumbs: [
                { path: "/campaigns", title: "campaigns" },
                { path: "/campaigns", title: "Campaign.group_campaign" },
              ],
              pageHeading: "Campaign.group_campaign",
            },
          },
        ],
      },

      {
        path: "/payment",
        name: "payment",
        children: [
          {
            path: "/payment/history",
            name: "payment-history",
            component: () =>
              import("@/views/publisher/pages/payment/history.vue"),
            meta: {
              pageTitle: "Payment History",
              breadcrumbs: ["payment", "paymentHistory"],
              pageHeading: "paymentHistory",
            },
          },
        ],
      },
      {
        path: "/payment/revenue",
        name: "payment-revenue",
        component: () => import("@/views/publisher/pages/payment/revenue.vue"),
        meta: {
          pageTitle: "Revenue",
          breadcrumbs: ["payment", "revenue"],
          pageHeading: "revenue",
        },
      },
      {
        path: "/notification",
        name: "notification",
        component: () =>
          import("@/views/publisher/pages/promotionalNews/notification.vue"),
        meta: {
          pageTitle: "Promotional News",
          breadcrumbs: ["promotionalNews"],
          pageHeading: "promotionalNews",
        },
      },
      {
        path: "/read-notification/:id",
        name: "read-notification",
        component: () =>
          import(
            "@/views/publisher/pages/promotionalNews/readNotification.vue"
          ),
        meta: {
          pageTitle: "Promotional details",
          breadcrumbs: ["promotionalNews"],
          pageHeading: "promotionalNews",
        },
      },
      {
        path: "/tools/create-link",
        name: "create-link",
        component: () => import("@/views/publisher/pages/tools/createLink.vue"),
        meta: {
          pageTitle: "linkManagement",
          breadcrumbs: ["tools", "linkManagement"],
          pageHeading: "linkManagement",
        },
      },
      {
        path: "/transgress",
        name: "transgress",
        children: [
          {
            path: "/transgress/violation-warning",
            name: "violation-warning",
            component: () =>
              import(
                "@/views/publisher/pages/violationWarning/violationWarning.vue"
              ),
            meta: {
              pageTitle: "violationWarning",
              breadcrumbs: ["transgress", "violationWarning"],
              pageHeading: "violationWarning",
            },
          },
        ],
      },
      {
        path: "/campaigns/result/:url",
        name: "campaigns-result",
        component: () =>
          import("@/views/publisher/pages/campaign/CampaignResult.vue"),
        meta: {
          pageTitle: "campaigns",
          breadcrumbs: [
            { path: "/campaigns", title: "Campaign List Page" },
            { path: "/", title: "Campaign All" },
          ],
        },
      },
      {
        path: "/campaigns/detail/:urlDetail",
        name: "campaigns-detail",
        component: () =>
          import(
            "@/views/publisher/pages/campaign/campaigndetail/CampaignDetail.vue"
          ),
        meta: {
          pageTitle: "Campaigns Detail",
        },
        children: [
          {
            path: "traffic",
            name: "Campaigns-Detail-Traffic",
            component: () =>
              import(
                "@/views/publisher/pages/campaign/campaigndetail/campaigndetailcomponent/CampaignTraffic.vue"
              ),
            meta: {
              pageTitle: "Campaign Details",
              breadcrumbs: [
                { path: "/campaigns", title: "Campaign List Page" },
                { path: "/", title: "CampaignsTraffic" },
              ],
              pageHeading: "Campaign Details",
            },
          },
          {
            path: "tracking-link",
            name: "Campaigns-Detail-Tracking-link",
            component: () =>
              import(
                "@/views/publisher/pages/campaign/campaigndetail/campaigndetailcomponent/CampaignTrackingLink.vue"
              ),
            meta: {
              pageTitle: "Campaign Tracking link",
              breadcrumbs: [
                { path: "/campaigns", title: "Campaign List Page" },
                { path: "/", title: "Link affiliate" },
              ],
              pageHeading: "Campaign Details",
            },
          },
          {
            path: "history",
            name: "Campaigns-Detail-history",
            component: () =>
              import(
                "@/views/publisher/pages/campaign/campaigndetail/campaigndetailcomponent/CampaignHistory.vue"
              ),
            meta: {
              pageTitle: "Campaign History",
              breadcrumbs: [
                { path: "/campaigns", title: "Campaign List Page" },
                { path: "/", title: "CampaignsHistory" },
              ],
              pageHeading: "Campaign Details",
            },
          },
          {
            path: "Deals-Coupon",
            name: "Campaigns-Detail-Deals-Coupon",
            component: () =>
              import(
                "@/views/publisher/pages/campaign/campaigndetail/campaigndetailcomponent/CampaignCoupon.vue"
              ),
            meta: {
              pageTitle: "Campaign Deals-Coupon",
              breadcrumbs: [
                { path: "/campaigns", title: "Campaign List Page" },
                { path: "/", title: "Deals&Coupon" },
              ],
              pageHeading: "Campaign Details",
            },
          },
          {
            path: "Creative",
            name: "Campaigns-Detail-Creative",
            component: () =>
              import(
                "@/views/publisher/pages/campaign/campaigndetail/campaigndetailcomponent/CampaignCreative.vue"
              ),
            meta: {
              pageTitle: "Campaign Creative",
              breadcrumbs: [
                { path: "/campaigns", title: "Campaign List Page" },
                { path: "/", title: "Creative" },
              ],
              pageHeading: "Campaign Details",
            },
          },
          {
            path: "Guide",
            name: "Campaigns-Detail-Guide",
            component: () =>
              import(
                "@/views/publisher/pages/campaign/campaigndetail/campaigndetailcomponent/CampaignGuide.vue"
              ),
            meta: {
              pageTitle: "Campaign Guide",
              breadcrumbs: [
                { path: "/campaigns", title: "Campaign List Page" },
                { path: "/", title: "CampaignsGuide" },
              ],
              pageHeading: "Campaign Details",
            },
          },
        ],
      },
      // {
      //   path: "/outstanding-community/hot_mentor/:type",
      //   name: "community-hot_mentor",
      //   component: () =>
      //     import(
      //       "@/views/publisher/pages/community/hotmentor/CommunityMentor.vue"
      //     ),
      //   meta: {
      //     pageTitle: "Outstanding Community",
      //     breadcrumbs: ["communityList"],
      //     pageHeading: "outstandingCommunity",
      //   },
      // },
      // {
      //   path: "/community",
      //   name: "community",
      //   children: [
      //     {
      //       path: "/community/member",
      //       name: "community-member",
      //       component: () =>
      //         import(
      //           "@/views/publisher/pages/community/mycommunity/listmember/ListMember.vue"
      //         ),
      //       meta: {
      //         pageTitle: "CommunityListMember",
      //         breadcrumbs: ["myCommunity", "CommunityListMember"],
      //         pageHeading: "CommunityListMember",
      //       },
      //     },
      //     {
      //       path: "/community/revenue",
      //       name: "community-revenue",
      //       component: () =>
      //         import(
      //           "@/views/publisher/pages/community/mycommunity/revenue/Revenue.vue"
      //         ),
      //       meta: {
      //         pageTitle: "CommunityRevenue",
      //         breadcrumbs: ["myCommunity", "CommunityRevenue"],
      //         pageHeading: "CommunityRevenue",
      //       },
      //     },
      //     {
      //       path: "/community/community-report-detail",
      //       name: "community-report-detail",
      //       component: () =>
      //         import(
      //           "@/views/publisher/pages/community/mycommunity/reportdetail/ReportDetail.vue"
      //         ),
      //       meta: {
      //         pageTitle: "detailedReport",
      //         breadcrumbs: ["myCommunity", "detailedReport"],
      //         pageHeading: "detailedReport",
      //       },
      //     },
      //   ],
      // },
      {
        path: "/coupons",
        name: "coupons",
        component: () =>
          import("@/views/publisher/pages/coupons/couponMain.vue"),
        children: [
          {
            path: "list",
            name: "coupon-list",
            component: () =>
              import("@/views/publisher/pages/coupons/couponList.vue"),
            meta: {
              pageTitle: "coupon.coupon_new",
              breadcrumbs: ["coupon.promotion", "coupon.coupon_new"],
              pageHeading: "coupon.coupon_new",
            },
          },
          {
            path: "my-coupon",
            name: "my-coupon-list",
            component: () =>
              import("@/views/publisher/pages/coupons/couponList.vue"),
            meta: {
              pageTitle: "coupon.coupon_my_coupon",
              breadcrumbs: ["coupon.promotion", "coupon.coupon_my_coupon"],
              pageHeading: "coupon.coupon_my_coupon",
            },
          },
          {
            path: "histories-use",
            name: "histories-use-coupon",
            component: () =>
              import("@/views/publisher/pages/coupons/couponHistoriesUse.vue"),
            meta: {
              pageTitle: "coupon.coupon_histories_use",
              breadcrumbs: ["coupon.promotion", "coupon.coupon_histories_use"],
              pageHeading: "coupon.coupon_histories_use",
            },
          },
        ],
      },
      {
        path: "/support/tickets/create",
        name: "support-tickets-create",
        component: () =>
          import("@/views/publisher/pages/support/sendticket/CreateTicket.vue"),
        meta: {
          pageTitle: "Send Ticket",
          breadcrumbs: ["support", "ticketsCreate"],
          pageHeading: "ticketsCreate",
        },
      },
      {
        path: "/support/tickets/list",
        name: "support-tickets-list",
        component: () =>
          import(
            "@/views/publisher/pages/support/managerticket/ListTicket.vue"
          ),
        meta: {
          pageTitle: "Ticket Management",
          breadcrumbs: ["support", "ticketsList"],
          pageHeading: "ticketsList",
        },
      },
      {
        path: "/support/tickets/detail/:urlDetail",
        name: "support-tickets-detail",
        component: () =>
          import(
            "@/views/publisher/pages/support/managerticket/TicketDetail.vue"
          ),
        meta: {
          pageTitle: "Detail ticket",
          breadcrumbs: ["Ticket Management", "Detail ticket"],
          pageHeading: "Detail ticket",
        },
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/publisher/pages/profile/Overview.vue"),
            meta: {
              pageTitle: "overview",
              breadcrumbs: ["community.user", "overview"],
              pageHeading: "overview",
            },
          },
          {
            path: "information",
            name: "profile-Information",
            component: () =>
              import("@/views/publisher/pages/profile/Information.vue"),
            meta: {
              pageTitle: "information",
              breadcrumbs: ["community.user", "information"],
              pageHeading: "information",
            },
          },
          {
            path: "payment",
            name: "profile-payment",
            component: () =>
              import("@/views/publisher/pages/profile/Payment.vue"),
            meta: {
              pageTitle: "payment",
              breadcrumbs: ["community.user", "payment"],
              pageHeading: "payment",
            },
          },
          {
            path: "password",
            name: "profile-password",
            component: () =>
              import("@/views/publisher/pages/profile/Password.vue"),
            meta: {
              pageTitle: "password",
              breadcrumbs: ["community.user", "password"],
              pageHeading: "password",
            },
          },
          {
            path: "traffic",
            name: "profile-traffic",
            component: () =>
              import("@/views/publisher/pages/profile/Traffic.vue"),
            meta: {
              pageTitle: "traffic",
              breadcrumbs: ["community.user", "traffic"],
              pageHeading: "traffic",
            },
          },
          {
            path: "referrals",
            name: "profile-referrals",
            component: () =>
              import("@/views/publisher/pages/profile/Referrals.vue"),
            meta: {
              pageTitle: "referrals",
              breadcrumbs: ["community.user", "referrals"],
              pageHeading: "referrals",
            },
          },
          {
            path: "logs",
            name: "profile-logs",
            component: () => import("@/views/publisher/pages/profile/Logs.vue"),
            meta: {
              pageTitle: "logs",
              breadcrumbs: ["community.user", "logs"],
              pageHeading: "logs",
            },
          },
          {
            path: "setting-notification",
            name: "profile-setting-notification",
            component: () =>
              import("@/views/publisher/pages/profile/SettingNofitication.vue"),
            meta: {
              pageTitle: "Notification",
              breadcrumbs: ["community.user", "notification"],
              pageHeading: "Notification",
            },
          },
          {
            path: "ApiKeys",
            name: "profile-ApiKeys",
            component: () =>
              import("@/views/publisher/pages/profile/ApiKey.vue"),
            meta: {
              pageTitle: "Api Keys",
              breadcrumbs: ["community.user", "Api Keys"],
              pageHeading: "Api Keys",
            },
          },
          {
            path: "change-phone",
            name: "change-phone",
            component: () =>
              import("@/views/publisher/pages/profile/ChangePhone.vue"),
            meta: {
              pageTitle: "titleChangePhone",
              breadcrumbs: ["titleChangePhone"],
            },
          },
        ],
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/publisher/modals/forms/CreateApiKey.vue"),
        meta: {
          pageTitle: "Create Api Key",
          breadcrumbs: ["Crafted", "Modals", "Forms"],
        },
      },
      {
        path: "/oauth2/v1/auth/identifier",
        name: "oauth2-identifier",
        component: () =>
          import(
            "@/views/publisher/authentication/basic-flow/SignInWithIndentifier.vue"
          ),
        meta: {
          pageTitle: "Sign In Indentifier",
        },
      },
      {
        path: "/omeez-community/:type",
        name: "community-hot_mentor",
        component: () =>
          import(
            "@/views/publisher/pages/v2_community/omeez_community/CommunityMentor.vue"
          ),
        meta: {
          pageTitle: "community.Highlighted_communities",
          breadcrumbs: ["community.Omeez_community"],
          pageHeading: "community.Omeez_community",
        },
      },
      {
        path: "/community-management",
        name: "community-management",
        component: () =>
          import(
            "@/views/publisher/pages/v2_community/community_management/index.vue"
          ),
        meta: {
          pageTitle: "community.My_community",
          middleware: ["auth", "communityManagement"],
          type: "mentor",
        },
        children: [
          {
            path: "overview",
            name: "community-management-overview",
            component: () =>
              import(
                "@/views/publisher/pages/v2_community/community_management/overview/index.vue"
              ),
            meta: {
              pageTitle: "community.Overview",
              breadcrumbs: [
                {
                  path: "/community-management/overview",
                  title: "community.My_community",
                },
                { path: "/", title: "community.Overview" },
              ],
              isScrollToTop: true,
              pageHeading: "community.Overview",
            },
          },
          {
            path: "revenue_details",
            name: "community-management-revenue",
            component: () =>
              import(
                "@/views/publisher/pages/v2_community/community_management/revenue_report/index.vue"
              ),
            meta: {
              pageTitle: "community.Revenue_from_community",
              breadcrumbs: [
                {
                  path: "/community-management/overview",
                  title: "community.My_community",
                },
                { path: "/", title: "community.Revenue_from_community" },
              ],
              isScrollToTop: true,
              pageHeading: "community.Revenue_from_community",
            },
          },
          {
            path: "member_management",
            name: "community-management-member",
            component: () =>
              import(
                "@/views/publisher/pages/v2_community/community_management/member_management/index.vue"
              ),
            meta: {
              pageTitle: "community.User_management",
              breadcrumbs: [
                {
                  path: "/community-management/overview",
                  title: "community.My_community",
                },
                { path: "/", title: "community.User_management" },
              ],
              isScrollToTop: true,
              pageHeading: "community.User_management",
            },
          },
          {
            path: "course_management",
            name: "community-management-course",
            component: () =>
              import(
                "@/views/publisher/pages/v2_community/community_management/course_management/index.vue"
              ),
            meta: {
              pageTitle: "community.Course_management",
              breadcrumbs: [
                {
                  path: "/community-management/overview",
                  title: "community.My_community",
                },
                { path: "/", title: "community.Course_management" },
              ],
              isScrollToTop: true,
              pageHeading: "community.Course_management",
            },
          },
          {
            path: "category_management",
            name: "community-management-category",
            component: () =>
              import(
                "@/views/publisher/pages/v2_community/community_management/category_management/index.vue"
              ),
            meta: {
              pageTitle: "community.Category_management",
              breadcrumbs: [
                {
                  path: "/community-management/overview",
                  title: "community.My_community",
                },
                { path: "/", title: "community.Category_management" },
              ],
              isScrollToTop: true,
              pageHeading: "community.Category_managementy",
            },
          },
        ],
      },
      {
        path: "/my-community",
        name: "my-community",
        component: () =>
          import("@/views/publisher/pages/v2_community/my_community/index.vue"),
        meta: {
          pageTitle: "community.Community_joined",
          middleware: ["auth", "communityJoined"],
          type: "publisher",
        },
        children: [
          {
            path: "overview",
            name: "my-community-overview",
            component: () =>
              import(
                "@/views/publisher/pages/v2_community/my_community/overview/index.vue"
              ),
            meta: {
              pageTitle: "community.Overview",
              breadcrumbs: [
                {
                  path: "/my-community/overview",
                  title: "community.Community_joined",
                },
                { path: "/", title: "community.Overview" },
              ],
              isScrollToTop: true,
              pageHeading: "community.Overview",
            },
          },
          {
            path: "course_list",
            name: "my-community-course",
            component: () =>
              import(
                "@/views/publisher/pages/v2_community/my_community/course_list/index.vue"
              ),
            meta: {
              pageTitle: "community.Course_list",
              breadcrumbs: [
                {
                  path: "/my-community/overview",
                  title: "community.Community_joined",
                },
                { path: "/", title: "community.Course_list" },
              ],
              isScrollToTop: true,
              pageHeading: "community.Course_list",
            },
          },
        ],
      },
      {
        path: "/community-management/edit",
        name: "community-management-eidt",
        component: () =>
          import(
            "@/views/publisher/pages/v2_community/community_management/edit_community/index.vue"
          ),
        meta: {
          pageTitle: "Edit Community",
          breadcrumbs: [
            {
              path: "/community-management/overview",
              title: "Community Management",
            },
            { path: "/", title: "Edit" },
          ],
          isScrollToTop: true,
          pageHeading: "Edit Community",
          middleware: ["auth", "communityManagement"],
          type: "mentor",
        },
      },
      {
        path: "/missions",
        name: "missions",
        redirect: "/missions/list",
        component: () =>
          import("@/views/publisher/pages/mission/MissionMain.vue"),
        children: [
          {
            path: "list",
            name: "mission-list",
            component: () =>
              import("@/views/publisher/pages/mission/MissionList.vue"),
            meta: {
              pageTitle: "mission.mission_list",
              breadcrumbs: ["mission.mission", "mission.mission_list"],
              pageHeading: "mission.mission_list",
            },
          },
          {
            path: "rewards-history",
            name: "rewards-history",
            component: () =>
              import("@/views/publisher/pages/mission/RewardsHistory.vue"),
            meta: {
              pageTitle: "mission.rewards_history",
              breadcrumbs: ["mission.mission", "mission.rewards_history"],
              pageHeading: "mission.rewards_history",
            },
          },
        ],
      },
      {
        path: "/membership",
        name: "membership",
        component: () => import("@/views/publisher/pages/membership/index.vue"),
        meta: {
          pageTitle: "membership.Title",
        },
        children: [
          {
            path: "your-ranking",
            name: "your-ranking",
            component: () =>
              import(
                "@/views/publisher/pages/membership/your_ranking/index.vue"
              ),
            meta: {
              pageTitle: "membership.Your_ranking",
              breadcrumbs: [
                {
                  path: "/membership/your-ranking",
                  title: "membership.Title",
                },
                { path: "/", title: "membership.Your_ranking" },
              ],
              isScrollToTop: true,
              pageHeading: "membership.Your_ranking",
            },
          },
          {
            path: "leaderboard",
            name: "leaderboard",
            component: () =>
              import(
                "@/views/publisher/pages/membership/leaderboard/index.vue"
              ),
            meta: {
              pageTitle: "membership.Leaderboard",
              breadcrumbs: [
                {
                  path: "/membership/your-ranking",
                  title: "membership.Title",
                },
                { path: "/", title: "membership.Leaderboard" },
              ],
              isScrollToTop: true,
              pageHeading: "membership.Leaderboard",
            },
          },
          {
            path: "gift-list",
            name: "gift-list",
            component: () =>
              import("@/views/publisher/pages/membership/gift_list/index.vue"),
            meta: {
              pageTitle: "membership.Gift_list",
              breadcrumbs: [
                {
                  path: "/membership/your-ranking",
                  title: "membership.Title",
                },
                { path: "/", title: "membership.Gift_list" },
              ],
              isScrollToTop: true,
              pageHeading: "membership.Gift_list",
            },
          },
          {
            path: "redemption-history",
            name: "redemption-history",
            component: () =>
              import(
                "@/views/publisher/pages/membership/gift_redemption_history/index.vue"
              ),
            meta: {
              pageTitle: "membership.Gift_redemption_history",
              breadcrumbs: [
                {
                  path: "/membership/your-ranking",
                  title: "membership.Title",
                },
                { path: "/", title: "membership.Gift_redemption_history" },
              ],
              isScrollToTop: true,
              pageHeading: "membership.Gift_redemption_history",
            },
          },
          {
            path: "points-history",
            name: "points-history",
            component: () =>
              import(
                "@/views/publisher/pages/membership/point_usage_history/index.vue"
              ),
            meta: {
              pageTitle: "membership.Point_usage_history",
              breadcrumbs: [
                {
                  path: "/membership/your-ranking",
                  title: "membership.Title",
                },
                { path: "/", title: "membership.Point_usage_history" },
              ],
              isScrollToTop: true,
              pageHeading: "membership.Point_usage_history",
            },
          },
          {
            path: "leaderboard",
            name: "leaderboard",
            component: () =>
              import(
                "@/views/publisher/pages/membership/leaderboard/index.vue"
              ),
            meta: {
              pageTitle: "membership.Leaderboards",
              breadcrumbs: [
                {
                  path: "/membership/your-ranking",
                  title: "membership.Title",
                },
                { path: "/", title: "membership.Leaderboards" },
              ],
              isScrollToTop: true,
              pageHeading: "membership.Leaderboards",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/publisher/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "signIn",
        },
      },
      {
        path: "/login-with-token/:token",
        name: "login-with-token",
        component: () =>
          import("@/views/publisher/authentication/basic-flow/SigninToken.vue"),
        meta: {
          pageTitle: "Login With Token",
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/publisher/authentication/basic-flow/SignUp.vue"),
        meta: {
          pageTitle: "signUp",
        },
      },
      {
        path: "/referral/:code",
        name: "referral",
        component: () =>
          import(
            "@/views/publisher/authentication/basic-flow/RedirectReferralcode.vue"
          ),
        meta: {
          pageTitle: "Sign Up",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import(
            "@/views/publisher/authentication/basic-flow/PasswordReset.vue"
          ),
        meta: {
          pageTitle: "Password reset",
        },
      },
      {
        path: "/password-new",
        name: "password-new",
        component: () =>
          import("@/views/publisher/authentication/basic-flow/NewPassword.vue"),
        meta: {
          pageTitle: "Password new",
        },
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: () =>
          import(
            "@/views/publisher/authentication/basic-flow/ForgotPassword.vue"
          ),
        meta: {
          pageTitle: "Forgot password",
        },
      },
      {
        path: "/email-verify/resend",
        name: "email-verify-resend",
        component: () =>
          import(
            "@/views/publisher/authentication/basic-flow/ResendMailVerify.vue"
          ),
        meta: {
          pageTitle: "Active account",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () =>
          import("@/views/publisher/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () =>
          import("@/views/publisher/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/KocLayout.vue"),
    children: [
      {
        path: "/dang-ky-koc",
        name: "Register KOC",
        component: () =>
          import("@/views/publisher/authentication/basic-flow/SignUpKoc.vue"),
        meta: {
          pageTitle: "Đăng ký KOC - Omeez",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  localStorage.setItem("IS_FIRST_ERROR", "1");
  if (to.meta?.middleware && typeof to.meta?.middleware === "object") {
    if (to.meta?.middleware[0] == "auth") {
      if (!store.getters.isUserAuthenticated) {
        return next({ name: "sign-in" });
      }

      if (Object.keys(store.getters.currentUser).length == 0) {
        await store.dispatch(Actions.VERIFY_AUTH, {});
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
      }
    }
    if (
      to.meta?.middleware[1] == "communityJoined" ||
      to.meta?.middleware[1] == "communityManagement"
    ) {
      if (!store.getters.communityVerificationData?.data) {
        await store.dispatch(Actions.COMMUNITY_CHECKING);
      }
      const communityData = store.getters.communityVerificationData?.data;
      const myCommunity = communityData?.my_community;
      const communityJoined = communityData?.follow_community;
      const publisherType = to.meta?.type;

      if (publisherType == "mentor" && myCommunity != null) {
        return next();
      } else if (publisherType == "publisher" && communityJoined != null) {
        return next();
      } else {
        return next({ name: "dashboard" });
      }
    }
  }
  i18n.global.locale.value = localStorage.getItem("lang")
    ? (localStorage.getItem("lang") as string) == "vi"
      ? "vi"
      : "en"
    : "vi";
  document.title =
    i18n.global.t(`${to.meta.pageTitle}`) + ` - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const urlHistoryRedirect = createWebHistory().location;
  localStorage.removeItem("urlHistoryRedirect");
  if (
    urlHistoryRedirect &&
    urlHistoryRedirect !== "/sign-in" &&
    urlHistoryRedirect !== "/dashboard"
  ) {
    localStorage.setItem("urlHistoryRedirect", urlHistoryRedirect);
  }
  if (from.fullPath == urlHistoryRedirect) {
    localStorage.removeItem("urlHistoryRedirect");
  }
  if ((localStorage.getItem("login_processing") as string) == "1") {
    next({ name: "update-info" });
  } else {
    if (
      store.getters.currentUser?.login_processing &&
      ((urlHistoryRedirect == "/sign-in" && to.fullPath == "/dashboard") ||
        (urlHistoryRedirect == "/" && to.fullPath == "/dashboard"))
    ) {
      next({ name: "update-info" });
    }
  }
  localStorage.removeItem("login_processing");
  next();
});

export default router;
