import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Traffic {
  item_data: unknown;
}

export interface TrafficReport {
  errors: unknown;
  Traffic: Traffic;
  isAuthenticated: boolean;
}

@Module
export default class ReportTrafficModule
  extends VuexModule
  implements TrafficReport
{
  errors = {};
  Traffic = {} as Traffic;
  isAuthenticated = !!JwtService.getToken();

  get getReportTraffic() {
    return this.Traffic;
  }

  @Mutation
  [Mutations.TRAFFIC_REPORT_DATA](Traffic) {
    this.Traffic = Traffic;
  }

  @Action
  [Actions.TRAFFIC_REPORT]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getData("/report/click")
        .then(({ data }) => {
          this.context.commit(Mutations.TRAFFIC_REPORT_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SEARCH_TRAFFIC_REPORT](dataSearch) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/report/click", dataSearch)
        .then(({ data }) => {
          this.context.commit(Mutations.TRAFFIC_REPORT_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
