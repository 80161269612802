import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Account {
  fullname: string;
  verify_status: string;
  address: string;
  identity_card_no: string;
  issued_date: string;
  issued_area: string;
  tax_identification_number: string;
  account_name: string;
  card_number: string;
  bank_name: string;
  branch_name: string;
  card_img_infront: string;
  card_img_behind: string;
  updated_at: string;
  password: string;
}

export interface AccountPaymentInfo {
  errors: unknown;
  Account: Account;
  isAuthenticated: boolean;
}
@Module
export default class AccountPaymentModule
  extends VuexModule
  implements AccountPaymentInfo
{
  errors = {};
  Account = {} as Account;
  isAuthenticated = !!JwtService.getToken();

  get getAccountPayment() {
    return this.paymentData;
  }

  @Mutation
  [Mutations.PAYMENTDATA](AccountPayment) {
    this.paymentData = AccountPayment;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.PAYMENT]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getData("/account/payment")
        .then(({ data }) => {
          this.context.commit(Mutations.PAYMENTDATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CHANGE_PAY](credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/account/payment/edit", credentials)
        .then(({ data }) => {
          if (data.code == "200") {
            this.context.commit(Mutations.SET_ERROR, {});
          } else {
            this.context.commit(Mutations.SET_ERROR, data);
          }
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
