import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Dashboard {}

export interface DashboardModuleInfo {
  errors: unknown;
  Dashboard: Dashboard;
  isAuthenticated: boolean;
}

@Module
export default class DashboardModule
  extends VuexModule
  implements DashboardModuleInfo
{
  errors = {};
  Dashboard = {} as Dashboard;
  isAuthenticated = !!JwtService.getToken();
  dataDashboarData = {};
  get getDataDashboardData() {
    return this.dataDashboardData;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.Dashboard = {} as Dashboard;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.DATA_DASHBOARD_DATA](dataDashboardData) {
    dataDashboardData.setTime = new Date();
    this.dataDashboardData = dataDashboardData;
  }

  @Action
  [Actions.DASHBOARD]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return true;
    } else {
      router.push({ name: "sign-in" });
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.DATA_DASHBOARD](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/data-dashboard", data)
        .then(({ data }) => {
          this.context.commit(Mutations.DATA_DASHBOARD_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
