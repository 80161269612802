import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export default class MissionModule extends VuexModule {
  @Action
  [Actions.MISSION_DATA_CHECKIN](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/mission/1/check-in", { params: data })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.MISSION_CHECKIN_DAILY](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("mission/1/check-in", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.MISSION_LIST](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/mission/list", { params: data })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.MISSION_GET_DETAIL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/mission/" + data.id + "/detail", {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.MISSION_PUBLISHER_JOIN_MISSION](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.post(
        "/mission/" + data.mission_id + "/join-mission",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.MISSION_REPORT_COMPLETED_MISSION](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.post(
        "/mission/" + data.get("mission_id") + "/report-complete",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
