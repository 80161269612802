enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGIN_TOKEN = "loginToken",
  LOGIN_GOOGLE = "loginGoole",
  DETAIL_PUBLISHER = "getDetailPublisher",
  LOGOUT = "logout",
  REGISTER = "register",
  SET_USER_DATA = "setUserData",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  INFORMATION = "account",
  PAYMENT = "payment",
  ADS = "advertisement",
  CREATE_ADS = "createAds",
  CHANGE_PAY = "changePay",
  CHANGE_PASSWORD = "changePassword",
  REFERRAL = "referral",
  INFORMATION_POST = "accountPost",
  LOGS = "logs",
  PAGINATION = "pagination",
  CHANGE_LANGUEGE = "changeLanguege",
  CONFIG = "config",
  SEARCH_ADS = "searchAds",
  CAMPAIGNS_GROUP = "campaignsGroup",
  CAMPAIGNS = "campaigns",
  SEARCH_CAMPAIGNS = "search_campaigns",
  CAMPAIGNS_DETAIL = "campaignsDetail",
  CAMPAIGNS_ID = "campaignsId",
  CAMPAIGNS_DETAIL_COUPON = "campaignsDetailCoupon",
  CAMPAIGNS_DETAIL_CREATIVE = "campaignsDetailCreative",
  CAMPAIGNS_DETAIL_Tracking_Link = "campaignsDetailTrackLink",
  CAMPAIGNS_DETAIL_Tracking_Link_POST = "campaignsDetailTrackLinkPost",
  CONVERSION_REPORT = "conversionReport",
  SEARCH_CONVERSION_REPORT = "searchConversionReport",
  SEARCH_JOURNEY_CONVERSION_REPORT = "searchJourneyConversionReport",
  CAMPAIGN_REPORT = "campaignReport",
  SEARCH_CAMPAIGN_REPORT = "searchCampaignReport",
  PAGINATION_CONVERSION = "paginationConversion",
  PAGINATION_CAMPAIGN = "paginationCampaign",
  SEARCH_TRAFFIC_REPORT = "searchTrafficReport",
  TRAFFIC_REPORT = "TrafficReport",
  CATEGORY = "category",
  COMMUNITY = "community",
  COMMUNITY_POST = "communityPost",
  CAMPAIGN_REGISTER = "campaignRegister",
  ADS_DELETE = "adsDelete",
  CHANGE_APPROVE = "changeApprove",
  DASHBOARD = "dashboard",
  CAMPAIGNS_DETAIL_CREATIVE_DOWLOAD_FILE = "campaignsDetailCreativeDowloadFile",
  CAMPAIGNS_DETAIL_HISTORY = "campaignsDetailHistory",
  COMMUNITY_MEMBER = "communityMember",
  COMMUNITY_MEMBER_SEARCH = "communityMemberSearch",
  COMMUNITY_MEMBER_POST = "communityMemberPost",
  COMMUNITY_REPORT_DETAIL = "communityReportDetail",
  COMMUNITY_REPORT_DETAIL_SEARCH = "communityReportDetailSearch",
  COMMUNITY_REPORT_DETAIL_CHART = "communityReportDetailChart",
  COMMUNITY_REVENUE = "communityRevenue",
  COMMUNITY_REVENUE_CHART = "communityRevenueChart",
  COMMUNITY_REPORT_MEMBER_CHART = "communityReportMemberChart",
  REPORT_LIST_DROPDOWN = "reportListDropdowm",
  COMMUNITY_SUGGESTION = "communitySuggestion",
  CITYLIST = "cityList",
  TICKET = "ticket",
  TICKET_DETAIL = "ticketDetail",
  TICKET_POST = "ticketPost",
  LIST_TEAM = "listTeam",
  TRANSACTION = "transaction",
  ATTACHMENT_CREATE = "attachmentCreate",
  ATTACHMENT_DETELE = "attachmentDelete",
  TICKET_UPDATE = "ticketUpdate",
  NOTIFICATION = "notification",
  NOTIFICATION_GROUPS = "notificationGroups",
  NOTIFICATION_DELETE = "notificationDelete",
  NOTIFICATION_DETAIL = "notificationDetail",
  COMMUNITY_CANCEL = "communityCancel",
  COMMUNITY_MENTOR_ID = "communityMentorId",
  REQUEST_CHANGE_PHONE = "requestChangePhone",
  CHANGE_PHONE = "changePhone",
  TRACKING_LINK = "trackingLink",
  PAYOUT_REVENUE = "payoutRevenue",
  PAYOUT_HISTORY = "payoutHistory",
  PAYOUT_HISTORY_DETAIL = "payoutHistoryDetail",
  VIOLATION = "violation",
  COMMUNITY_LEAVE = "communityLeave",
  REVENUE_REPORT = "RevenueReport",
  NOTIFICATION_UPDATE = "notificationUpdate",
  NOTIFICATION_UPDATE_STATUS_UNREAD = "notificationUpdateStatusUnred",
  NOTIFICATION_READ_ALL = "notificationReadAll",
  DASHBOARD_REVENUE = "dashboardRevenue",
  LIST_DROPDOWN_TOOL = "listDropdowmTool",
  NOTIFICATION_LIST_SIMPLE = "notificationListSimple",
  NOTIFICATION_BANNERS = "notificationBanners",
  DETAIL_PAYOUT_REVENUE = "detailPayoutRevenue",
  LIST_DROPDOWN_CAMPAIGNS = "listDropdowmCampaignsTool",
  DATA_DASHBOARD = "dataDashboard",
  SUGGEST_BY_LINK = "suggestByLink",
  SOCIAL_LOGIN_PROCESS = "socialLoginProcess",
  NOTIFICATION_TOTAL_UNREAD = "notificationTotalUnread",
  TICKET_READ_ALL = "ticketReadAll",
  RESEND_EMAIL_VERIFY = "resendEmailVerify",
  CHANGE_SETTING_NOTIFICATION = "changeSettingNotification",
  GET_SETTING_NOTIFICATION = "getSettingNotificationPublisher",
  V2_GET_DATA_CAMPAIGN = "getDataCampaignV2",
  VERIFY_CONFIRM_EMAIL = "checkVerifyEmail",
  CONFIRM_OAUTH_INDENTIFIER_APP = "confirmOathIndentifierApp",
  DATA_CONFIG_FOR_REGISTER = "getDataConfigForRegister",
  REGISTER_KOC = "registerKoc",
  COMMUNITY_MANAGEMENT_DETAIL = "communityManagementDetail",
  COMMUNITY_MANAGEMENT_OVERVIEW = "communityManagementOverview",
  COMMUNITY_MANAGEMENT_MEMBER_CHART = "communityManagementMemberChart",
  COMMUNITY_MANAGEMENT_REVENUE_CHART = "communityManagementRevenueChart",
  COMMUNITY_MANAGEMENT_REVENUE_DETAILS_CHART = "communityManagementRevenueDetailsChart",
  COMMUNITY_MANAGEMENT_REVENUE_DETAILS = "communityManagementRevenueDetails",
  COMMUNITY_MANAGEMENT_EDIT_INFORMATION = "communityManagementEditInformation",
  COMMUNITY_MANAGEMENT_MEMBER_LIST = "communityManagamentMemberList",
  COMMUNITY_MANAGEMENT_MEMBER_ACTION = "communityManagamentMemberAction",
  COMMUNITY_MANAGEMENT_MEMBER_DETAIL_MEMBER = "communityMangamentMemberDetailMember",
  MY_COMMUNITY_OVERVIEW = "MyCommunityOverview",
  MY_COMMUNITY_LEAVE = "MyCommunityLeave",
  COMMUNITY_CHECKING = "communityChecking",
  TICKET_COMMENTS = "getTicketComments",
  REQUEST_CHANGE_INFO_BY_OTP = "requestChangeInfoByOtp",
  VERIFY_CHANGE_INFO_BY_OTP = "verifyChangeInfoByOtp",
  RESEND_SEND_OTP = "resendSendOtp",
  GROUPS_CAMPAIGN = "getGroupsCampaign",
  GROUPS_CAMPAIGN_DETAIL = "getGroupsCampaignDetail",
  GROUPS_CAMPAIGN_ITEMS = "getItemsGroupsCampaign",
  GROUPS_CAMPAIGN_LIST = "getCampaignGroupAll",
  MY_CAMPAIGNS = "getMyCampaign",
  CAMPAIGN_QUICK_SEARCH = "getQuickSearchCampaign",
  MISSION_DATA_CHECKIN = "missionDataCheckIn",
  MISSION_CHECKIN_DAILY = "missionCheckInDaily",
  MISSION_LIST = "missionList",
  MISSION_GET_DETAIL = "missionGetDetail",
  MISSION_PUBLISHER_JOIN_MISSION = "publisherJoinMission",
  MISSION_REPORT_COMPLETED_MISSION = "reportCompletedMission",
  COUPON_GET_DETAIL = "couponGetDetail",
  COUPON_PUBLIC_LIST = "couponPublicList",
  COUPON_CONFIG_DATA = "couponConfigData",
  COUPON_SAVE_PUBLIC = "couponSavePublic",
  COUPON_SAVE_SHARE = "couponSaveShare",
  COUPON_MY_COUPON = "couponMyCoupon",
  COUPON_HISTORIES_USE = "couponHistoriesUse",
  COUPON_CONFIRM_USE = "couponConfirmUse",
  MEMBERSHIP_REWARD_HISTORIES = "membershipRewardPointHistories",
  MEMBERSHIP_RANKING_DATA = "membershipRankingData",
  MEMBERSHIP_CATEGORY_LIST = "membershipCategoryList",
  MEMBERSHIP_GIFT_LIST = "MembershipGiftList",
  MEMBERSHIP_GIFT_DETAILS = "membershipGiftDetails",
  MEMBERSHIP_GIFT_REDEMPTION_HISTORY = "membershipGiftRedemtionHistory",
  MEMBERSHIP_REDEEM_GIFT = "membershipRedeemGift",
  MEMBERSHIP_LEADERBOARD = "membershipLeaderBoard",
  AM_SUPPORT_INFO = "getInfoAMSupport",
  TOP_NEWS = "getTopNews",
  UPDATE_INFO_FIRST_LOGIN = "updateInfoFirstLogin",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  PURGE_AUTH_OR_SET_ERROR = "purgeAuthsetError",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_USER_DATA = "setUserData",
  SET_CURRENCY_USER = "setCurrencyUser",
  SET_COMMUNITY_FOLLOW = "setCommunityFollow",
  SET_MEMBERSHIP = "setMemberShip",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG_PROPERTY = "setLayoutConfigProperty",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
  ACCOUNTDATA = "accountData",
  PAYMENTDATA = "paymentData",
  ADSDATA = "advertisementData",
  SET_PASSWORD_PUB = "setPasswordPub",
  REFERRAL_DATA = "referralData",
  INFORMATION_DATA = "accountData",
  SETTING_NOTIFICATION = "settingNotification",
  INFORMATION_POST_DATA = "accountPostData",
  LOGS_DATA = "logsData",
  PAGINATION_DATA = "paginationData",
  CHANGE_LANGUEGE_DATA = "changeLanguegeData",
  CONFIG_DATA = "configData",
  CAMPAIGNSGROUP_DATA = "campaignGroupData",
  GROUPS_CAMPAIGN_LIST_DATA = "groupCampaignListData",
  GROUPS_CAMPAIGN_HOME_DATA = "groupsCampaignHomeData",
  CAMPAIGNS_DETAIL_DATA = "campaignDetailData",
  CAMPAIGNS_DETAIL_COUPON_DATA = "campaignsDetailCouponData",
  CAMPAIGNS_DETAIL_CREATIVE_DATA = "campaignsDetailCreativeData",
  CAMPAIGNS_DETAIL_Tracking_Link_DATA = "campaignsDetailTrackLinkData",
  CAMPAIGNS_DETAIL_Tracking_Link_POST_DATA = "campaignsDetailTrackLinkPostData",
  CAMPAIGNS_DATA = "campaignsData",
  CONVERSION_REPORT_DATA = "conversionData",
  CAMPAIGN_REPORT_DATA = "campaignReportData",
  SET_ERROR_PASSWORD = "setErrorPassword",
  CHANGE_PASSWORD_DATA = "changePasswordData",
  TRAFFIC_REPORT_DATA = "TrafficReportData",
  CATEGORY_DATA = "categoryData",
  COMMUNITY_DATA = "communityData",
  CAMPAIGN_REGISTER_DATA = "campaignRegisterData",
  CAMPAIGNS_DETAIL_Tracking_Link_DATA_POST = "campaignsDetailTrackLinkDataPost",
  CAMPAIGNS_DETAIL_HISTORY_DATA = "campaignsDetailHistoryData",
  COMMUNITY_MEMBER_DATA = "communityMemberData",
  COMMUNITY_REPORT_DETAIL_DATA = "communityReportDetailData",
  COMMUNITY_REPORT_DETAIL_CHART_DATA = "communityReportDetailChartData",
  COMMUNITY_REVENUE_DATA = "communityRevenueData",
  COMMUNITY_REVENUE_CHART_DATA = "communityRevenueChartData",
  COMMUNITY_REPORT_MEMBER_CHART_DATA = "communityReportMemberChartData",
  REPORT_LIST_DROPDOWN_DATA = "reportListDropdowmData",
  MENTOR_SUGGEST_DATA = "mentorSuggestData",
  CITYLIST_DATA = "cityListData",
  TICKET_DATA = "ticketData",
  TICKET_DETAIL_DATA = "ticketDetailData",
  LIST_TEAM_DATA = "getListTeamData",
  TRANSACTION_DATA = "transactionData",
  ATTACHMENT_CREATE_DATA = "attachmentCreateData",
  ATTACHMENT_DETELE_DATA = "attachmentDeleteData",
  TICKET_UPDATE_DATA = "ticketUpdateData",
  NOTIFICATION_DATA = "notificationData",
  NOTIFICATION_GROUPS_DATA = "notificationGroupsData",
  NOTIFICATION_DETAIL_DATA = "notificationDetailData",
  COMMUNITY_MENTOR_ID_DATA = "communityMentorIdData",
  CHANGE_PHONE_DATA = "changePhoneData",
  SET_ERROR_PHONE = "setErrorPhone",
  TRACKING_LINK_DATA = "trackingLinkData",
  PAYOUT_REVENUE_DATA = "payoutRevenueData",
  PAYOUT_HISTORY_DATA = "payoutHistoryData",
  PAYOUT_HISTORY_DETAIL_DATA = "payoutHistoryDetailData",
  VIOLATION_DATA = "violationData",
  REVENUE_REPORT_DATA = "RevenueReportData",
  COMMUNITY_POST_DATA = "communityPostData",
  DASHBOARD_REVENUE_DATA = "dashboardRevenueData",
  LIST_DROPDOWN_TOOL_DATA = "listDropdowmToolData",
  NOTIFICATION_LIST_SIMPLE_DATA = "notificationListSimpleData",
  NOTIFICATION_BANNERS_DATA = "notificationBannersData",
  DETAIL_PAYOUT_REVENUE_DATA = "detailPayoutRevenueData",
  LIST_DROPDOWN_CAMPAIGNS_DATA = "listDropdowmCampaignsToolData",
  DATA_DASHBOARD_DATA = "dataDashboardData",
  SUGGEST_BY_LINK_DATA = "suggestByLinkData",
  NOTIFICATION_TOTAL_UNREAD_DATA = "notificationTotalUnreadData",
  COMMUNITY_CHECKING_DATA = "communityCheckingData",
  AM_SUPPORT_INFO = "setInfoAMSupport",
  TOP_NEWS_DATA = "setTopNewsData",
  COMMUNITY_MANAGEMENT_OVERVIEW_DATA = "communityManagementOverviewData",
  COMMUNITY_MANAGEMENT_MEMBER_CHART_DATA = "communityManagementMemberChartData",
  COMMUNITY_MANAGEMENT_REVENUE_CHART_DATA = "communityManagementRevenueChartData",
}

export { Actions, Mutations };
