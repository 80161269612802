import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface communityOverviewData {
  overview: object;
  expiredTime: unknown;
}
export interface communityMemberChartData {
  dataChart: object;
  expiredTime: unknown;
  type: number;
}
export interface communityRevenueChartData {
  dataChart: object;
  expiredTime: unknown;
  type: number;
}
export interface CommunityDashboad {
  errors: unknown;
  CommunityDashboad: CommunityDashboad;
  communityOverviewData: communityOverviewData;
  communityMemberChartData: communityMemberChartData;
  communityRevenueChartData: communityRevenueChartData;
  isAuthenticated: boolean;
}

@Module
export default class CommunityDashboardModule
  extends VuexModule
  implements CommunityDashboad
{
  errors = {};
  CommunityDashboad = {} as CommunityDashboad;
  communityOverviewData = {} as communityOverviewData;
  communityMemberChartData = {} as communityMemberChartData;
  communityRevenueChartData = {} as communityRevenueChartData;
  isAuthenticated = !!JwtService.getToken();

  @Mutation
  [Mutations.COMMUNITY_MANAGEMENT_MEMBER_CHART_DATA](communityMemberChartData) {
    this.communityMemberChartData = communityMemberChartData;
    this.communityMemberChartData.expiredTime = new Date();
    this.communityRevenueChartData.type = communityMemberChartData?.data?.type;
  }

  @Mutation
  [Mutations.COMMUNITY_MANAGEMENT_REVENUE_CHART_DATA](
    communityRevenueChartData
  ) {
    console.log(communityRevenueChartData);
    this.communityRevenueChartData = communityRevenueChartData;
    this.communityRevenueChartData.expiredTime = new Date();
    this.communityRevenueChartData.type = communityRevenueChartData?.data?.type;
  }

  @Mutation
  [Mutations.COMMUNITY_MANAGEMENT_OVERVIEW_DATA](communityOverviewData) {
    this.communityOverviewData = communityOverviewData;
    this.communityOverviewData.expiredTime = new Date();
  }

  get getCommunityMemberChartData2(): any {
    return this.communityMemberChartData;
  }

  get getCommunityRevenueChartData2(): any {
    return this.communityRevenueChartData;
  }

  get getCommunityOverviewData(): any {
    return this.communityOverviewData;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  async [Actions.COMMUNITY_MANAGEMENT_DETAIL]() {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.query("/community/my-community", {})
        .then((data) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  async [Actions.COMMUNITY_MANAGEMENT_OVERVIEW]() {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.query("/community/my-community/overview", {})
        .then((data) => {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(
            Mutations.COMMUNITY_MANAGEMENT_OVERVIEW_DATA,
            data.data
          );
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  async [Actions.COMMUNITY_MANAGEMENT_MEMBER_CHART](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 1);
      return await ApiService.post("/community/report_member", payload)
        .then((data) => {
          this.context.commit(Mutations.SET_ERROR, payload);
          return data.data;
          // this.context.commit(
          //   Mutations.COMMUNITY_MANAGEMENT_MEMBER_CHART_DATA,
          //   data.data
          // );
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  async [Actions.COMMUNITY_MANAGEMENT_REVENUE_CHART](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 1);
      return await ApiService.post("/community/revenue", payload)
        .then((data) => {
          this.context.commit(Mutations.SET_ERROR, payload);
          return data.data;
          // this.context.commit(
          //   Mutations.COMMUNITY_MANAGEMENT_REVENUE_CHART_DATA,
          //   data.data
          // );
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.COMMUNITY_MANAGEMENT_REVENUE_DETAILS_CHART](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 1);
      return ApiService.post("community/detail/revenue", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, payload);
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.COMMUNITY_MANAGEMENT_REVENUE_DETAILS](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 1);
      return ApiService.post("community/detail/revenue-detail", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, payload);
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.COMMUNITY_MANAGEMENT_EDIT_INFORMATION](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("community/my-community/update-info", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, payload);
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
