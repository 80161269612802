import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ThemeModeModule from "@/store/modules/ThemeModeModule";
import AccountAdsModule from "@/store/modules/AccountModule/AccountAdsModule";
import AccountPaymentModule from "@/store/modules/AccountModule/AccountPaymentModule";
import AccountReffeReferralModule from "@/store/modules/AccountModule/AccountReffeReferralModule";
import AccountChangePassWordModule from "@/store/modules/AccountModule/AccountChangePassWordModule";
import InformationModule from "@/store/modules/AccountModule/AccountInformationModule";
import AccountActivityModule from "@/store/modules/AccountModule/AccountLogsModule";
import ApiConfigModule from "@/store/modules/ConfigApi/ConfigModule";
import CampaignModule from "@/store/modules/Campaigns/CampaignModule";
import CampaignDetailModule from "@/store/modules/Campaigns/CampaignDetailModule";
import CampaignDetailCouponModule from "@/store/modules/Campaigns/CampaignDetailCouponModule";
import CampaignDetailCreativeModule from "@/store/modules/Campaigns/CampaignDetailCreativeModule";
import CampaignDetailTrackingLinksModule from "@/store/modules/Campaigns/CampaignDetailTrackingLinksModule";
import ReportConversionModule from "@/store/modules/Report/ReportConversionModule";
import ReportCampaignsModule from "@/store/modules/Report/ReportCampaignsModule";
import ReportTrafficModule from "@/store/modules/Report/ReportTrafficModule";
import CategoryModule from "@/store/modules/Category/CategoryModule";
// import CommunityModule from "@/store/modules/Community/CommunityModule";
import DashboardModule from "@/store/modules/DashboardModule";
import CampaignDetailHistoryModule from "@/store/modules/Campaigns/CampaignDetailHistoryModule";
// import CommunityMemberModule from "@/store/modules/Community/CommunityMemberModule";
import CommunityReportDetailModule from "@/store/modules/Community/CommunityReportDetailModule";
import CommunityRevenuelModule from "@/store/modules/Community/CommunityRevenuelModule";
import CityListModule from "@/store/modules/ConfigApi/CityListModule";
import TicketModule from "@/store/modules/Ticket/TicketModule";
import TicketDetailModule from "@/store/modules/Ticket/TicketDetailModule";
import NotificationModule from "@/store/modules/PromotionalNews/NotificationModule";
import NotificationDetailModule from "@/store/modules/PromotionalNews/NotificationDetailModule";
import AccountChangePhoneModule from "@/store/modules/AccountModule/AccountChangePhoneModule";
import ListTrankingLinkModule from "@/store/modules/Tool/ListTrankingLinkModule";
import PayoutRevenueModule from "@/store/modules/Payment/PayoutRevenueModule";
import PayoutHistoryModule from "@/store/modules/Payment/PayoutHistoryModule";
import ViolationModule from "@/store/modules/Violation/ViolationModule";
import ReportRevenueModule from "@/store/modules/Report/ReportRevenueModule";
import CommunityDashboardModule from "@/store/modules/v2_Community/CommunityDashboardModule";
import CommunityMemberManagementModule from "@/store/modules/v2_Community/CommunityMemberManagementModule";
import MyCommunityDashboardModule from "@/store/modules/v2_Community/MyCommunityDashboardModule";
import OmeezCommunityModule from "./modules/v2_Community/OmeezCommunityModule";
import MembershipModule from "@/store/modules/Membership/MembershipModule";
import MembershipGiftModule from "@/store/modules/Membership/MembershipGiftModule";
import MissionModule from "@/store/modules/Mission/MissionModule";
import CouponModule from "@/store/modules/Coupon/CouponModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    AccountAdsModule,
    AccountPaymentModule,
    AccountReffeReferralModule,
    AccountChangePassWordModule,
    InformationModule,
    AccountActivityModule,
    ApiConfigModule,
    CampaignModule,
    CampaignDetailModule,
    CampaignDetailCouponModule,
    CampaignDetailCreativeModule,
    CampaignDetailTrackingLinksModule,
    ReportConversionModule,
    ReportCampaignsModule,
    ReportTrafficModule,
    CategoryModule,
    // CommunityModule,
    DashboardModule,
    CampaignDetailHistoryModule,
    // CommunityMemberModule,
    CommunityReportDetailModule,
    CommunityRevenuelModule,
    CityListModule,
    TicketModule,
    TicketDetailModule,
    NotificationModule,
    NotificationDetailModule,
    AccountChangePhoneModule,
    ListTrankingLinkModule,
    PayoutRevenueModule,
    PayoutHistoryModule,
    ViolationModule,
    ReportRevenueModule,
    CommunityDashboardModule,
    CommunityMemberManagementModule,
    MyCommunityDashboardModule,
    OmeezCommunityModule,
    MissionModule,
    CouponModule,
    MembershipModule,
    MembershipGiftModule,
  },
});

export default store;
