import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface AccountInfo {
  errors: unknown;
  Account: AccountChangePass;
  isAuthenticated: boolean;
}

export interface AccountChangePass {
  password_old: string;
  password: string;
  password_confirm: string;
}
@Module
export default class AccountChangePassWordModule
  extends VuexModule
  implements AccountInfo
{
  errors = {};
  Account = {} as AccountChangePass;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get authentification errors
   * @returns array
   */

  get getAccountPassword() {
    return this.Account;
  }

  @Action
  [Actions.CHANGE_PASSWORD](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/account/change_password", data)
        .then(({ data }) => {
          this.context.commit(Mutations.CHANGE_PASSWORD_DATA, data);
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
