import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface OmeezCommunity {
  errors: unknown;
  OmeezCommunity: OmeezCommunity;
  isAuthenticated: boolean;
}

@Module
export default class OmeezCommunityModule
  extends VuexModule
  implements OmeezCommunity
{
  errors = {};
  OmeezCommunity = {} as OmeezCommunity;
  isAuthenticated = !!JwtService.getToken();

  get getCommunityData() {
    return this.OmeezCommunity;
  }

  @Mutation
  [Mutations.COMMUNITY_DATA](OmeezCommunity) {
    this.OmeezCommunity = OmeezCommunity;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.COMMUNITY](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/community/hot-mentor", { params: data })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.COMMUNITY_POST](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/community/register", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.COMMUNITY_CANCEL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/community/cancel-register", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.COMMUNITY_MENTOR_ID](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("community/" + data.menterId + "/detail", {})
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.COMMUNITY_LEAVE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/community/leave", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
