import { createI18n } from "vue-i18n";
import viMessage from "@/lang/vi.json";
import enMessage from "@/lang/en.json";

const messages = {
  en: enMessage,
  vi: viMessage,
};

const i18n = createI18n({
  legacy: false,
  locale: "vi",
  globalInjection: true,
  messages,
});

export default i18n;
