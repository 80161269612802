import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ajaxRequestBlock, toastMsgError } from "@/core/helpers/toastMessage";
import EventBus from "vue3-eventbus";
import router from "@/router";

export interface User {
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  password: string;
  api_token: string;
  membership: {
    unused_points: number;
    accumulated_points: number;
    rank: string;
  };
  communities: {
    id: number;
    name: string;
    community_code: string;
    status: number;
    actived_at: Date;
  };
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  errorsAll = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  mentor = {};
  communityData = {};
  currency_user = {};
  get currencyUser() {
    return this.currency_user;
  }
  /**
   * Get current user object
   * @returns User
   */
  get currentUser() {
    return this.user;
  }
  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  get mentorSuggest() {
    return this.mentor;
  }

  get communityVerificationData() {
    return this.communityData;
  }

  @Mutation
  [Mutations.MENTOR_SUGGEST_DATA](Mentor) {
    this.mentor = Mentor;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
    this.errorsAll = {};
    JwtService.saveToken(user.data.token);
  }
  @Mutation
  [Mutations.SET_CURRENCY_USER](data) {
    this.currency_user = data;
  }
  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }
  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    this.errorsAll = {};
    JwtService.destroyToken();
  }

  @Mutation
  async [Mutations.PURGE_AUTH_OR_SET_ERROR](responseData) {
    let error = {} as any;
    if (responseData.code == 401) {
      this.isAuthenticated = false;
      this.user = {} as User;
      this.errors = [];
      this.errorsAll = {};
      JwtService.destroyToken();
      error = {
        errors: {
          errors_mess: [responseData.message],
        },
      };
    } else {
      if (responseData.code == 403 || responseData?.code_msg == 4031) {
        if (localStorage.getItem("IS_FIRST_ERROR") == "1") {
          localStorage.setItem("IS_FIRST_ERROR", "0");
          ajaxRequestBlock(responseData.message, () => {
            console.log();
          }).then(async (result) => {
            if (result.isConfirmed) {
              if (responseData?.code_msg == 4031) {
                JwtService.destroyToken();
              } else {
                await router.push({ name: "report-traffic" });
              }
              location.reload();
            }
          });
        }
      }
      error = {
        errors: {
          errors_mess: [responseData.message],
        },
      };
      if (responseData?.code_msg == 4221) {
        error.errors.errors_code = responseData?.code_msg;
      }
    }
    this.errors = { ...error };
    this.errorsAll = { ...responseData };
  }
  @Mutation
  [Mutations.COMMUNITY_CHECKING_DATA](communityData) {
    this.communityData = communityData;
  }
  @Action
  [Actions.LOGIN](credentials) {
    ApiService.setHeader();
    return ApiService.post("/login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        this.context.commit(Mutations.SET_USER, data.data.publisher);
        this.context.commit(Mutations.SET_CURRENCY_USER, data.data.currency);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.LOGIN_TOKEN](token) {
    return ApiService.post("/login-with-token/" + token, {})
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        this.context.commit(Mutations.SET_USER, data.data.publisher);
        this.context.commit(Mutations.SET_CURRENCY_USER, data.data.currency);
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.LOGOUT]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getData("/logout")
        .then(({ data }) => {
          this.context.commit(Mutations.PURGE_AUTH);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.REGISTER](credentials) {
    ApiService.setHeader();
    return ApiService.post("/register", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.REGISTER_KOC](credentials) {
    ApiService.setHeader("", 2);
    return ApiService.post("/register-koc", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.RESEND_EMAIL_VERIFY](payload) {
    return ApiService.post("email/resend", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("verify_token", payload)
        .then(({ data }) => {
          data.data.publisher.type = payload.type ?? 1;
          this.context.commit(Mutations.SET_USER, data.data.publisher);
          this.context.commit(Mutations.SET_CURRENCY_USER, data.data.currency);
          localStorage.setItem(
            "login_processing",
            data?.data?.publisher?.login_processing
          );
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.VERIFY_CONFIRM_EMAIL](payload) {
    ApiService.setHeader();
    return await ApiService.post("check-verify-email", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }
  @Action
  async [Actions.CONFIRM_OAUTH_INDENTIFIER_APP](payload) {
    ApiService.setHeader();
    return await ApiService.post("check-oauth-indentifier-app", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.COMMUNITY_SUGGESTION](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("/community/community-suggestion", data)
        .then(({ data }) => {
          this.context.commit(
            Mutations.MENTOR_SUGGEST_DATA,
            data?.data?.mentors?.items
          );
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.COMMUNITY_CHECKING]() {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.query("/community/check-community", {})
        .then(({ data }) => {
          this.context.commit(Mutations.COMMUNITY_CHECKING_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.UPDATE_INFO_FIRST_LOGIN](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("account/update-first-login-step-1", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
