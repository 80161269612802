import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface TrankingLink {
  errors: unknown;
  TrankingLink: TrankingLink;
  isAuthenticated: boolean;
}

@Module
export default class ListTrankingLinkModule
  extends VuexModule
  implements TrankingLink
{
  errors = {};
  TrankingLink = {} as TrankingLink;
  isAuthenticated = !!JwtService.getToken();

  get getTrankingLinkAllData() {
    return this.TrankingLink;
  }

  get getListDropdowmToolData() {
    return this.listDropdowmToolData;
  }

  get getlistDropdowmCampaignsToolData() {
    return this.listDropdowmCampaignsToolData;
  }

  get getSuggestByLinkData() {
    return this.suggestByLinkData;
  }

  @Mutation
  [Mutations.TRACKING_LINK_DATA](TrankingLink) {
    this.TrankingLink = TrankingLink;
  }

  @Mutation
  [Mutations.LIST_DROPDOWN_TOOL_DATA](listDropdowmToolData) {
    this.listDropdowmToolData = listDropdowmToolData;
  }

  @Mutation
  [Mutations.LIST_DROPDOWN_CAMPAIGNS_DATA](listDropdowmCampaignsToolData) {
    this.listDropdowmCampaignsToolData = listDropdowmCampaignsToolData;
  }

  @Mutation
  [Mutations.SUGGEST_BY_LINK_DATA](suggestByLinkData) {
    this.suggestByLinkData = suggestByLinkData;
  }

  @Action
  [Actions.TRACKING_LINK](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/tools/tracking_links", data)
        .then(({ data }) => {
          this.context.commit(Mutations.TRACKING_LINK_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.LIST_DROPDOWN_TOOL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/report/list-dropdown", data)
        .then(({ data }) => {
          this.context.commit(Mutations.LIST_DROPDOWN_TOOL_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.LIST_DROPDOWN_CAMPAIGNS](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/tools/list_campaign", data)
        .then(({ data }) => {
          this.context.commit(Mutations.LIST_DROPDOWN_CAMPAIGNS_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SUGGEST_BY_LINK](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/tools/suggest_by_link", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SUGGEST_BY_LINK_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
