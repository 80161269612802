import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface AccountInfo {
  errors: unknown;
  Account: AccountChangePhone;
  isAuthenticated: boolean;
}

export interface AccountChangePhone {
  phone: string;
  token: string;
}
@Module
export default class AccountChangePhoneModule
  extends VuexModule
  implements AccountInfo
{
  errors = {};
  Account = {} as AccountChangePhone;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get authentification errors
   * @returns array
   */

  get getAccountPhone() {
    return this.Account;
  }

  get getErrorsPhone() {
    return this.setErrorPhone;
  }

  @Mutation
  [Mutations.SET_ERROR_PHONE](error) {
    this.setErrorPhone = { ...error };
  }

  @Action
  [Actions.REQUEST_CHANGE_INFO_BY_OTP](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("/account/request-change-info-by-otp", payload)
        .then(({ data }) => {
          const error = {
            errors: {
              errors_mess: null,
            },
          };
          this.context.commit(Mutations.CHANGE_PHONE_DATA, data);
          this.context.commit(Mutations.SET_ERROR_PHONE, error);
          return data.data;
        })
        .catch(({ response }) => {
          const error = {
            errors: {
              errors_mess: response.data.message,
            },
          };
          this.context.commit(Mutations.SET_ERROR_PHONE, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CHANGE_PHONE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/account/change-phone", payload)
        .then(({ data }) => {
          const error = {
            errors: {
              errors_mess: null,
            },
          };
          this.context.commit(Mutations.CHANGE_PHONE_DATA, data);
          this.context.commit(Mutations.SET_ERROR_PHONE, error);
        })
        .catch(({ response }) => {
          const error = {
            errors: {
              errors_mess: [response.data.message],
            },
          };
          this.context.commit(Mutations.SET_ERROR_PHONE, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.VERIFY_CHANGE_INFO_BY_OTP](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("/account/verify-change-info-otp", payload)
        .then(({ data }) => {
          const error = {
            errors: {
              errors_mess: null,
            },
          };
          this.context.commit(Mutations.SET_ERROR_PHONE, error);
          return data.data;
        })
        .catch(({ response }) => {
          const error = {
            errors: {
              errors_mess: response.data.message,
            },
          };
          this.context.commit(Mutations.SET_ERROR_PHONE, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.RESEND_SEND_OTP](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("/account/resend-send-otp", payload)
        .then(({ data }) => {
          const error = {
            errors: {
              errors_mess: null,
            },
          };
          this.context.commit(Mutations.SET_ERROR_PHONE, error);
          return data.data;
        })
        .catch(({ response }) => {
          const error = {
            errors: {
              errors_mess: response.data.message,
            },
          };
          this.context.commit(Mutations.SET_ERROR_PHONE, error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
