import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
export interface Information {
  first_name: string;
  last_name: string;
  login_name: string;
  email: string;
  phone_number: string;
  company: string;
  national: string;
  referral_code: string;
  auto_approve_flg: string;
  avatar: string;
  email_verified_at: string;
  level_id: string;
  birthday: string;
  province_code: string;
  time_zone: string;
  currency_id: string;
  introduce: string;
  facebook: string;
  youtube: string;
  intagram: string;
  tiktok: string;
  national_obj: string;
  currency_obj: string;
  time_zone_obj: string;
  category_ids: string;
  referral_publisher: string;
  gender_id: string;
}

export interface AccountInformationInfo {
  errors: unknown;
  Information: Information;
  isAuthenticated: boolean;
}

@Module
export default class AccountInformationModule
  extends VuexModule
  implements AccountInformationInfo
{
  errors = {};
  Information = {} as Information;
  isAuthenticated = !!JwtService.getToken();
  setting_notification = {
    enable_conversion: 1,
  };

  get getInformationData(): Information {
    return this.Information;
  }
  get getSettingNotification() {
    return this.setting_notification;
  }
  @Mutation
  [Mutations.INFORMATION_DATA](Information) {
    this.Information = Information;
  }
  @Mutation
  [Mutations.SETTING_NOTIFICATION](data) {
    this.setting_notification = data;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.INFORMATION](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.query("/account", {})
        .then(({ data }) => {
          this.context.commit(Mutations.INFORMATION_DATA, data.data.publisher);
          this.context.commit(Mutations.SET_USER, {
            id: data.data.publisher.id,
            publisher_code: data.data.publisher.publisher_code,
            first_name: data.data.publisher.first_name,
            last_name: data.data.publisher.last_name,
            email: data.data.publisher.email,
            login_name: data.data.publisher.login_name,
            status: data.data.publisher.status,
            is_done_first_login: data.data.publisher.is_done_first_login,
            login_processing: data.data.publisher.login_processing,
            publisher_type_name: data.data.publisher.publisher_type_name,
            avatar_url: data.data.publisher.avatar_url,
            membership: data.data.publisher.membership,
            communities: data.data.publisher.communities,
          });
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.INFORMATION_POST](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post("/account/edit", payload, {
        headers: { "content-type": "multipart/form-data" },
      })
        .then(({ data }) => {
          this.context.commit(Mutations.INFORMATION_DATA, data);
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CHANGE_LANGUEGE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("/lang", payload).catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CHANGE_APPROVE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/account/inline/edit", payload)
        .then(() => {
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SOCIAL_LOGIN_PROCESS](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/account/login-process", payload)
        .then(() => {
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.CHANGE_SETTING_NOTIFICATION](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/account/setting_notification", payload)
        .then((data) => {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.SETTING_NOTIFICATION, data.data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.GET_SETTING_NOTIFICATION](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.query("/account/setting_notification", {})
        .then((data) => {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.SETTING_NOTIFICATION, data.data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
