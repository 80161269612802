import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Category {
  name: string;
}

export interface CategoryInfo {
  errors: unknown;
  Category: Category;
  isAuthenticated: boolean;
}

@Module
export default class CategoryModule extends VuexModule implements CategoryInfo {
  errors = {};
  Category = {} as Category;
  isAuthenticated = !!JwtService.getToken();

  get getCategoryData(): Category {
    return this.Category;
  }

  @Mutation
  [Mutations.CATEGORY_DATA](Category) {
    this.Category = Category;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
}
