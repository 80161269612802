import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Account {
  first_name: string;
  last_name: string;
  login_name: string;
  email: string;
  phone_number: string;
  company: string;
  national: string;
  referral_code: string;
  auto_approve_flg: string;
  avatar: string;
  email_verified_at: string;
  level_id: string;
  birthday: string;
  province_code: string;
  time_zone: string;
  currency_id: string;
  introduce: string;
  facebook: string;
  youtube: string;
  intagram: string;
  tiktok: string;
  national_obj: string;
  currency_obj: string;
  time_zone_obj: string;
  categories: string;
  referral_publisher: string;
  fullname: string;
  verify_status: string;
  address: string;
  identity_card_no: string;
  issued_date: string;
  issued_area: string;
  tax_identification_number: string;
  account_name: string;
  card_number: string;
  bank_name: string;
  branch_name: string;
  card_img_infront: string;
  card_img_behind: string;
  updated_at: string;
  password: string;
}

export interface AccountInfo {
  errors: unknown;
  Account: Account;
  isAuthenticated: boolean;
}

@Module
export default class AccountAdsModule
  extends VuexModule
  implements AccountInfo
{
  errors = {};
  Account = {} as Account;
  isAuthenticated = !!JwtService.getToken();

  get getAccountAds() {
    return this.advertisementData;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.Account.password = password;
  }

  @Mutation
  [Mutations.ADSDATA](accountAds) {
    this.advertisementData = accountAds;
  }

  @Mutation
  [Mutations.ACCOUNTDATA](Account) {
    this.accountData = Account;
  }

  @Action
  [Actions.CREATE_ADS](credentials) {
    return ApiService.post("/account/ads/create", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.SEARCH_ADS](dataSearch) {
    return ApiService.post("/account/ads", dataSearch)
      .then(({ data }) => {
        this.context.commit(Mutations.ADSDATA, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.ADS_DELETE](dataSearch) {
    return ApiService.post("/account/ads/delete", dataSearch)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }
}
