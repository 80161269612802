import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CampaignDetailCreative {
  campaign_code: string;
}

export interface CampaignDetailCouponInfo {
  errors: unknown;
  CampaignDetailCreative: CampaignDetailCreative;
  isAuthenticated: boolean;
}

@Module
export default class CampaignDetailCreativeModule
  extends VuexModule
  implements CampaignDetailCouponInfo
{
  errors = {};
  CampaignDetailCreative = {} as CampaignDetailCreative;
  isAuthenticated = !!JwtService.getToken();

  get getCampaignDetailCreativeData(): CampaignDetailCreative {
    return this.CampaignDetailCreative;
  }

  @Mutation
  [Mutations.CAMPAIGNS_DETAIL_CREATIVE_DATA](CampaignDetailCreative) {
    this.CampaignDetailCreative = CampaignDetailCreative;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.CAMPAIGNS_DETAIL_CREATIVE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getDataDetail(
        "/campaign",
        data.campaign_code,
        "creative",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.CAMPAIGNS_DETAIL_CREATIVE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.CAMPAIGNS_DETAIL_CREATIVE_DOWLOAD_FILE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getDataDetail(
        "/campaign",
        data.campaign_code,
        "creative/download",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.CAMPAIGNS_DETAIL_CREATIVE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
