import { ajaxRequestBlock } from "@/core/helpers/toastMessage";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "axios";
export interface Campaign {
  campaign_name: string;
  profit: string;
  timezone: string;
  tatus: string;
  access_status: string;
  is_show_home: string;
  campaign_type_device: string;
  app_id: string;
  preview_link: string;
  tracking_link: string;
  currency_type: string;
  category: string;
  original_link: string;
  is_accept_original_link: string;
  campaign_type: string;
  national: string;
  registration_terms: string;
  cookies_timeline: string;
  payment_date_estimates: string;
  ip_whitelist: string;
  ip_whitelist_enable: string;
  relationship_flg: string;
  bot_flg: string;
  spam_flg: string;
  approve_auto: string;
  avatar: string;
  avatar_url: string;
  campaign_publisher_last: string;
}

export interface CampaignInfo {
  errors: unknown;
  Campaign: Campaign;
  isAuthenticated: boolean;
}

@Module
export default class CampaignModule extends VuexModule implements CampaignInfo {
  errors = {};
  Campaign = {} as Campaign;
  CampaignGroupList = {};
  GroupsCampaignHome = [];
  isAuthenticated = !!JwtService.getToken();

  get getCampaignData(): Campaign {
    return this.Campaign;
  }
  get geCampaignGroupList() {
    return this.CampaignGroupList;
  }
  get getGroupsCampaignHome() {
    return this.GroupsCampaignHome;
  }
  get getCampaignRegisterData() {
    return this.campaignRegisterData;
  }

  @Mutation
  [Mutations.CAMPAIGNSGROUP_DATA](Campaign) {
    this.Campaign = Campaign;
  }
  @Mutation
  [Mutations.GROUPS_CAMPAIGN_HOME_DATA](data) {
    data = data?.data?.groups?.items;
    data.setTime = new Date();
    this.GroupsCampaignHome = data;
  }
  @Mutation
  [Mutations.GROUPS_CAMPAIGN_LIST_DATA](data) {
    this.CampaignGroupList = data.groups;
  }
  @Mutation
  [Mutations.CAMPAIGNS_DATA](Campaign) {
    this.Campaign = Campaign;
  }
  @Mutation
  [Mutations.CAMPAIGN_REGISTER_DATA](campaignRegisterData) {
    this.campaignRegisterData = campaignRegisterData;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
  @Action
  [Actions.CAMPAIGNS_ID](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getDataDetail(
        "/campaign",
        data.campaign_code,
        "detail",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.CAMPAIGNSGROUP_DATA, data);
        })
        .catch(({ response }) => {
          if (response.data.code == 405) {
            ajaxRequestBlock(response.data.message, () => {
              console.log();
            }).then(async (result) => {
              if (result.isConfirmed) {
                router.push({ name: "profile-campaigns" });
              }
            });
          } else {
            this.context.commit(
              Mutations.PURGE_AUTH_OR_SET_ERROR,
              response.data
            );
          }
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.CAMPAIGN_REGISTER](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/campaign/register", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.CAMPAIGN_REGISTER_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  //v2
  @Action
  async [Actions.GROUPS_CAMPAIGN](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.query("/campaign/groups-campaign", {
        params: data,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.GROUPS_CAMPAIGN_HOME_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.GROUPS_CAMPAIGN_LIST](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.post("/campaign/all-group-campaign", data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.GROUPS_CAMPAIGN_LIST_DATA, data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.GROUPS_CAMPAIGN_DETAIL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.query(
        "/campaign/groups-campaign/" + data.id + "/detail",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.GROUPS_CAMPAIGN_ITEMS](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      const params = JSON.parse(JSON.stringify(data));
      delete params.cancelToken;
      return await ApiService.query(
        "/campaign/groups-campaign/" + data.id + "/campaign-items",
        { params: params, cancelToken: data.cancelToken }
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.MY_CAMPAIGNS](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      const params = JSON.parse(JSON.stringify(data));
      delete params.cancelToken;
      return await ApiService.post("/campaign/my-campaign", params, {
        cancelToken: data.cancelToken,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          if (axios.isCancel(response)) {
            console.log("Request canceled:");
          } else {
            this.context.commit(
              Mutations.PURGE_AUTH_OR_SET_ERROR,
              response.data
            );
          }
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.CAMPAIGNS](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      const params = JSON.parse(JSON.stringify(data));
      delete params.cancelToken;
      return ApiService.post("/campaign", params, {
        cancelToken: data.cancelToken,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          if (axios.isCancel(response)) {
            console.log("Request canceled:");
          } else {
            this.context.commit(
              Mutations.PURGE_AUTH_OR_SET_ERROR,
              response.data
            );
          }
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.CAMPAIGN_QUICK_SEARCH](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      const params = JSON.parse(JSON.stringify(data));
      delete params.cancelToken;
      return await ApiService.post("/campaign/quick-search", params, {
        cancelToken: data.cancelToken,
      })
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data.data;
        })
        .catch(({ response }) => {
          if (axios.isCancel(response)) {
            console.log("Request canceled:");
          } else {
            this.context.commit(
              Mutations.PURGE_AUTH_OR_SET_ERROR,
              response.data
            );
          }
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
