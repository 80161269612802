import { Tooltip, Popover } from "bootstrap";

const tooltip = {
  mounted(el: HTMLElement) {
    const tooltip = new Tooltip(el);
  },
};
const popover = {
  mounted(el: HTMLElement, binding) {
    const popover = new Popover(el, {
      title: binding.value.title,
      content: binding.value.content,
      placement: binding.value.placement || "top",
      html: binding.value.html || true,
      trigger: binding.value.trigger || "hover",
    });
  },
};
export { tooltip, popover };
