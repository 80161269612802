import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Traffic {
  item_data: unknown;
}

export interface Revenue {
  errors: unknown;
  Revenue: Revenue;
  isAuthenticated: boolean;
}

@Module
export default class ReportRevenue extends VuexModule implements Revenue {
  errors = {};
  Revenue = {} as Revenue;
  isAuthenticated = !!JwtService.getToken();

  get getReportRevenue() {
    return this.Revenue;
  }

  @Mutation
  [Mutations.REVENUE_REPORT_DATA](Revenue) {
    this.Revenue = Revenue;
  }

  @Action
  [Actions.REVENUE_REPORT](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/report/revenue", data)
        .then(({ data }) => {
          this.context.commit(Mutations.REVENUE_REPORT_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
