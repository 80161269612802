import Swal from "sweetalert2/dist/sweetalert2.min.js";
import i18n from "../plugins/i18n";
import router from "@/router";

const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 5000,
});

const toastMsg = (msg: string, type = "success"): void => {
  Toast.fire({
    icon: type,
    title: msg,
  });
};
const toastMsgSuccess = (msg: string): void => {
  Toast.fire({
    icon: "success",
    background: "#CBE9BF",
    title: msg,
    timer: 4000,
  });
};
const toastMsgError = (msg: string): void => {
  Toast.fire({
    icon: "error",
    background: "#ED8FA3",
    title: msg,
  });
};

const alertMsg = (msg: string, icon = "success", btn = "btn-danger") => {
  const btnCustom = "btn fw-semobold " + btn;

  return Swal.fire({
    html: msg,
    icon: icon,
    showCloseButton: true,
    confirmButtonText: "OK",
    customClass: {
      confirmButton: btnCustom,
    },
  });
};

const alertMsgHtml = (
  type: boolean,
  msg: string,
  msgtitle: string,
  btn = "btn-danger"
) => {
  const btnCustom = "btn fw-semobold " + btn;
  let swalPopup = "" as any;
  if (type) {
    swalPopup = Swal.fire({
      html: msg,
      icon: "error",
      showCloseButton: true,
      confirmButtonText: "OK",
      customClass: {
        confirmButton: btnCustom,
        container: "my-custom-class",
      },
    });
  } else {
    swalPopup = Swal.fire({
      title: msgtitle,
      html: msg,
      showCloseButton: true,
      confirmButtonText: "OK",
      customClass: {
        confirmButton: btnCustom,
        container: "my-custom-class",
      },
    });
  }

  const homeLink = document.querySelector("#url-link") as any;
  homeLink.addEventListener("click", (e) => {
    e.preventDefault();
    router.push({ name: "email-verify-resend" });
    swalPopup.close();
  });
};

const confirmMsg = (msg: string) => {
  return Swal.fire({
    title: msg,
    icon: "warning",
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: i18n.global.t("common.yes"),
    cancelButtonText: i18n.global.t("common.no"),
    customClass: {
      confirmButton: "btn fw-semobold btn-primary",
      cancelButton: "btn fw-semobold btn-light-danger",
    },
  });
};

const alertTwoButtonWithLink = (
  msg: string,
  icon = "success",
  btn1_text = "OK",
  btn2_text = "Cancel",
  btn1_class = "btn-success",
  btn2_class = "btn-danger",
  link1 = "",
  link2 = "",
  elementTarget,
  reverseButtons = true
) => {
  return Swal.fire({
    title: msg,
    icon: icon,
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonText: btn1_text,
    cancelButtonText: btn2_text,
    reverseButtons: reverseButtons,
    customClass: {
      confirmButton: btn1_class,
      cancelButton: btn2_class,
    },
  }).then((result) => {
    const elementTg = document.getElementById(elementTarget);

    if (result.isConfirmed) {
      if (link1.trim()) {
        elementTg?.setAttribute("href", link1);
        elementTg?.click();
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      if (link2.trim()) {
        elementTg?.setAttribute("href", link2);
        elementTg?.click();
      }
    }
  });
};

const ajaxRequest = (msg: string, preconfirmFunction) => {
  return Swal.fire({
    title: msg,
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: i18n.global.t("common.yes"),
    cancelButtonText: i18n.global.t("common.no"),
    preConfirm: preconfirmFunction,
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
  });
};

const ajaxRequestBlock = (msg: string, preconfirmFunction) => {
  const btnCustom = "btn fw-semobold " + "btn-danger";
  return Swal.fire({
    title: msg,
    icon: "warning",
    confirmButtonText: "OK",
    preConfirm: preconfirmFunction,
    showLoaderOnConfirm: true,
    allowOutsideClick: false,
    customClass: {
      confirmButton: btnCustom,
    },
  });
};

const closeSwal = () => {
  Swal.close();
};

export {
  toastMsg,
  toastMsgSuccess,
  toastMsgError,
  alertMsg,
  confirmMsg,
  ajaxRequest,
  closeSwal,
  ajaxRequestBlock,
  alertMsgHtml,
  alertTwoButtonWithLink,
};
