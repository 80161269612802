import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Config {
  errors: unknown;
  Config: Config;
  isAuthenticated: boolean;
}

@Module
export default class ConfigModule extends VuexModule implements Config {
  errors = {};
  Config = {} as Config;
  isAuthenticated = !!JwtService.getToken();

  get getConfigData() {
    return this.Config;
  }

  @Mutation
  [Mutations.CONFIG_DATA](Config) {
    this.Config = Config;
  }

  @Action
  [Actions.CONFIG](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/config", data)
        .then(({ data }) => {
          this.context.commit(Mutations.CONFIG_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.DATA_CONFIG_FOR_REGISTER](payload) {
    ApiService.setHeader("", 2);
    return await ApiService.query("/config/data-register", payload)
      .then((response) => {
        this.context.commit(Mutations.SET_ERROR, {});
        return response.data;
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
      });
  }
}
