import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { number } from "@intlify/core-base";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CommunityRevenue {
  first_name: string;
  last_name: string;
  avatar: string;
  facebook: string;
  introduce: string;
  intagram: string;
  tiktok: string;
  referral_publisher_for_viewer: {
    id: number;
    referral_publisher_id: number;
    publisher_id: number;
    status: number;
    status_obj: {
      id: number;
      name: number;
    };
  };
  referral_code: string;
  total_referral_publishers: {
    referral_publisher_id: number;
    count_member: number;
  };
  earned_money: {
    referral_publisher_id: number;
    count_member: number;
  };
}

export interface CommunityRevenueInfo {
  errors: unknown;
  CommunityRevenue: CommunityRevenue;
  isAuthenticated: boolean;
}

@Module
export default class CommunityRevenuelModule
  extends VuexModule
  implements CommunityRevenueInfo
{
  errors = {};
  CommunityRevenue = {} as CommunityRevenue;
  isAuthenticated = !!JwtService.getToken();

  get getCommunityRevenueData(): CommunityRevenue {
    return this.CommunityRevenue;
  }

  get getCommunityRevenueChartData() {
    return this.communityRevenueChartData;
  }

  get getcommunityReportMemberChartDataData() {
    return this.communityReportMemberChartData;
  }

  @Mutation
  [Mutations.COMMUNITY_REVENUE_DATA](CommunityRevenue) {
    this.CommunityRevenue = CommunityRevenue;
  }
  @Mutation
  [Mutations.COMMUNITY_REPORT_MEMBER_CHART_DATA](
    communityReportMemberChartData
  ) {
    this.communityReportMemberChartData = communityReportMemberChartData;
  }

  @Mutation
  [Mutations.COMMUNITY_REVENUE_CHART_DATA](communityRevenueChartData) {
    this.communityRevenueChartData = communityRevenueChartData;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.COMMUNITY_REVENUE]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getData("/account/referral")
        .then(({ data }) => {
          this.context.commit(Mutations.COMMUNITY_REVENUE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.COMMUNITY_REVENUE_CHART](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/community/revenue", data)
        .then(({ data }) => {
          this.context.commit(Mutations.COMMUNITY_REVENUE_CHART_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.COMMUNITY_REPORT_MEMBER_CHART](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/community/report_member", data)
        .then(({ data }) => {
          this.context.commit(
            Mutations.COMMUNITY_REPORT_MEMBER_CHART_DATA,
            data
          );
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
