import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Campaign {
  first_name: string;
  last_name: string;
  login_name: string;
  email: string;
  phone_number: string;
  company: string;
  national: string;
  referral_code: string;
}

export interface CampaignReport {
  errors: unknown;
  Campaign: Campaign;
  isAuthenticated: boolean;
}

@Module
export default class ReportCampaignsModule
  extends VuexModule
  implements CampaignReport
{
  errors = {};
  Campaign = {} as Campaign;
  isAuthenticated = !!JwtService.getToken();

  get getReportCampaign() {
    return this.campaignReportData;
  }

  @Mutation
  [Mutations.CAMPAIGN_REPORT_DATA](Campaign) {
    this.campaignReportData = Campaign;
  }

  @Action
  [Actions.CAMPAIGN_REPORT]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getData("/report/campaign")
        .then(({ data }) => {
          this.context.commit(Mutations.CAMPAIGN_REPORT_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SEARCH_CAMPAIGN_REPORT](dataSearch) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/report/campaign", dataSearch)
        .then(({ data }) => {
          this.context.commit(Mutations.CAMPAIGN_REPORT_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
