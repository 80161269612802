import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface TicketDetail {
  errors: unknown;
  TicketDetail: TicketDetail;
  isAuthenticated: boolean;
}

@Module
export default class TicketDetailModule
  extends VuexModule
  implements TicketDetail
{
  errors = {};
  TicketDetail = {} as TicketDetail;
  isAuthenticated = !!JwtService.getToken();

  get getTicketDetailData() {
    return this.TicketDetail;
  }

  @Mutation
  [Mutations.TICKET_DETAIL_DATA](TicketDetail) {
    this.TicketDetail = TicketDetail;
  }

  @Action
  async [Actions.TICKET_DETAIL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.query("/tickets/detail/" + data.urlDetail, {
        params: {},
      })
        .then(({ data }) => {
          const error = {};
          this.context.commit(Mutations.SET_ERROR, error);
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  async [Actions.TICKET_COMMENTS](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return await ApiService.query("/tickets/" + data.codeCase + "/comments", {
        params: data,
      })
        .then(({ data }) => {
          const error = {};
          this.context.commit(Mutations.SET_ERROR, error);
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
  @Action
  [Actions.TICKET_UPDATE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader("", 2);
      return ApiService.post(
        "/tickets/" + payload.get("code_case") + "/create-comment",
        payload
      )
        .then(({ data }) => {
          const error = {};
          this.context.commit(Mutations.SET_ERROR, error);
          this.context.commit(Mutations.TICKET_UPDATE_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.TICKET_READ_ALL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/tickets/read-all-ticket", data).catch(
        ({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        }
      );
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
