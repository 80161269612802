import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CampaignDetailCoupon {
  campaign_code: string;
}

export interface CampaignDetailCouponInfo {
  errors: unknown;
  CampaignDetailCoupon: CampaignDetailCoupon;
  isAuthenticated: boolean;
}

@Module
export default class CampaignDetailCouponModule
  extends VuexModule
  implements CampaignDetailCouponInfo
{
  errors = {};
  CampaignDetailCoupon = {} as CampaignDetailCoupon;
  isAuthenticated = !!JwtService.getToken();

  get getCampaignDetailCouponData(): CampaignDetailCoupon {
    return this.CampaignDetailCoupon;
  }

  @Mutation
  [Mutations.CAMPAIGNS_DETAIL_COUPON_DATA](CampaignDetailCoupon) {
    this.CampaignDetailCoupon = CampaignDetailCoupon;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.CAMPAIGNS_DETAIL_COUPON](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getDataDetail(
        "/campaign",
        data.campaign_code,
        "deal-coupon",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.CAMPAIGNS_DETAIL_COUPON_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
