import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface NotificationDetail {
  errors: unknown;
  NotificationDetail: NotificationDetail;
  isAuthenticated: boolean;
}

@Module
export default class NotificationDetailModule
  extends VuexModule
  implements NotificationDetail
{
  errors = {};
  NotificationDetail = {} as NotificationDetail;
  isAuthenticated = !!JwtService.getToken();

  get getNotificationDetailData() {
    return this.NotificationDetail;
  }

  @Mutation
  [Mutations.NOTIFICATION_DETAIL_DATA](NotificationDetail) {
    this.NotificationDetail = NotificationDetail;
  }

  @Action
  [Actions.NOTIFICATION_DETAIL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getDataDetailNoParams(
        "/notification",
        data.id,
        "detail"
      )
        .then(({ data }) => {
          this.context.commit(Mutations.NOTIFICATION_DETAIL_DATA, data);
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.NOTIFICATION_UPDATE](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/notification/update-status", data).catch(
        ({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        }
      );
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.NOTIFICATION_UPDATE_STATUS_UNREAD](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/notification/update-unread", data).catch(
        ({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        }
      );
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.NOTIFICATION_READ_ALL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/notification/update-read-all", data).catch(
        ({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        }
      );
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
