import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import { tooltip, popover } from "./bootstrap";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import vue3GoogleLogin from "vue3-google-login";
import SetupInterceptors from "@/core/services/SetupInterceptors";
import VueRecaptcha from "vue3-recaptcha-v2";
import eventBus from "vue3-eventbus";
import "@/core/plugins/prismjs";
import VueGtag from "vue-gtag";
const app = createApp(App);
app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_GOOGLE_LOGIN,
});
app.use(VueRecaptcha, {
  siteKey:
    process.env.VUE_APP_RECAPTCHA_SITE_KEY ??
    "6LepQCEjAAAAAGWxVvvaSPM064jvz2fd85tMZanK",
  alterDomain: false, // default: false
});
app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(eventBus);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
SetupInterceptors();

app.directive("tooltip", tooltip);
app.directive("popoverc", popover);
app.use(VueGtag, {
  config: { id: "G-V3XR0YE9W3" },
});
app.mount("#app");
