import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CampaignDetailTrackingLink {
  campaign_code: string;
}

export interface CampaignDetailTrackingLinkInfo {
  errors: unknown;
  CampaignDetailTrackingLink: CampaignDetailTrackingLink;
  isAuthenticated: boolean;
}

@Module
export default class CampaignDetailTrackingLinksModule
  extends VuexModule
  implements CampaignDetailTrackingLinkInfo
{
  errors = {};
  CampaignDetailTrackingLink = {} as CampaignDetailTrackingLink;
  isAuthenticated = !!JwtService.getToken();

  get getCampaignDetailTrackingLinkData() {
    return this.CampaignDetailTrackingLink;
  }
  get getCampaignsDetailTrackLinkDataPost() {
    return this.campaignsDetailTrackLinkDataPost;
  }

  @Mutation
  [Mutations.CAMPAIGNS_DETAIL_Tracking_Link_DATA](CampaignDetailTrackingLink) {
    this.CampaignDetailTrackingLink = CampaignDetailTrackingLink;
  }

  @Mutation
  [Mutations.CAMPAIGNS_DETAIL_Tracking_Link_DATA_POST](
    campaignsDetailTrackLinkDataPost
  ) {
    this.campaignsDetailTrackLinkDataPost = campaignsDetailTrackLinkDataPost;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.CAMPAIGNS_DETAIL_Tracking_Link](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getDataDetail(
        "/campaign",
        data.campaign_code,
        "tracking_links",
        data
      )
        .then(({ data }) => {
          this.context.commit(
            Mutations.CAMPAIGNS_DETAIL_Tracking_Link_DATA,
            data
          );
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.CAMPAIGNS_DETAIL_Tracking_Link_POST](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getDataDetail(
        "/campaign",
        data.campaign_code,
        "tracking_link/create",
        data
      )
        .then(({ data }) => {
          const error = {};
          this.context.commit(Mutations.SET_ERROR, error);
          this.context.commit(
            Mutations.CAMPAIGNS_DETAIL_Tracking_Link_DATA_POST,
            data
          );
        })
        .catch(async ({ response }) => {
          await this.context.commit(
            Mutations.PURGE_AUTH_OR_SET_ERROR,
            response.data
          );
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
