import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CampaignDetail {
  campaign_code: string;
}

export interface CampaignDetailInfo {
  errors: unknown;
  CampaignDetail: CampaignDetail;
  isAuthenticated: boolean;
}

@Module
export default class CampaignDetailModule
  extends VuexModule
  implements CampaignDetailInfo
{
  errors = {};
  CampaignDetail = {} as CampaignDetail;
  isAuthenticated = !!JwtService.getToken();

  get getCampaignDetailData(): CampaignDetail {
    return this.CampaignDetail;
  }

  @Mutation
  [Mutations.CAMPAIGNSGROUP_DATA](CampaignDetail) {
    this.CampaignDetail = CampaignDetail;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.CAMPAIGNS_DETAIL](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getDataDetail(
        "/campaign",
        data.campaign_code,
        "detail_info",
        data
      )
        .then(({ data }) => {
          this.context.commit(Mutations.CAMPAIGNSGROUP_DATA, data);
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
