
import { defineComponent, nextTick, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { themeMode } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import EventBus from "vue3-eventbus";
declare const LC_API: any;
export default defineComponent({
  name: "app",
  data() {
    return {
      status: true,
      data: [],
      interval: null as any,
    };
  },
  mounted() {
    this.reNew();
  },
  methods: {
    reNew() {
      EventBus.emit("getTotalNotificationSuccess");
    },
  },
  created() {
    this.interval = setInterval(() => {
      if (!document.hidden) {
        this.reNew();
      } else {
        var myTimer = setInterval(this.interval, 90000);
        clearInterval(myTimer);
        myTimer = setInterval(this.interval, 90000);
      }
    }, 90000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  setup() {
    const store = useStore();
    //Bau Omee: Set name cho livechat
    const current_user = computed(() => store.getters.currentUser);
    // watch(current_user, (newVal, oldVal) => {
    //   setTimeout(function () {
    //     if (
    //       typeof LC_API.set_visitor_name == "function" &&
    //       typeof current_user.value.data != "undefined" &&
    //       current_user.value.data.publisher.email
    //     ) {
    //       var publisher = current_user.value.data.publisher;
    //       LC_API.set_visitor_name(
    //         publisher.first_name + " " + publisher.last_name
    //       );
    //       LC_API.set_visitor_email(publisher.email);
    //       LC_API.set_custom_variables([
    //         {
    //           name: "Full Name",
    //           value: publisher.first_name + " " + publisher.last_name,
    //         },
    //         { name: "Email ", value: publisher.email },
    //         { name: "Phone", value: publisher.phone_number },
    //       ]);
    //     }
    //   }, 1000);
    // });
    onMounted(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      /**
       *  Sets a mode from configuration
       */
      store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);

      nextTick(() => {
        initializeComponents();
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
    });
  },
});
