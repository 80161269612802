import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { useI18n } from "vue-i18n";
export interface Report {
  first_name: string;
  last_name: string;
  login_name: string;
  email: string;
  phone_number: string;
  company: string;
  national: string;
  referral_code: string;
  item_data: unknown;
}

export interface ConversionReport {
  errors: unknown;
  Report: Report;
  isAuthenticated: boolean;
}

@Module
export default class ReportConversionModule
  extends VuexModule
  implements ConversionReport
{
  errors = {};
  Report = {} as Report;
  isAuthenticated = !!JwtService.getToken();

  get getStatusConversion() {
    const { t } = useI18n();
    return {
      1: {
        background: "#ffce50",
        name: t("Report.sc_1"),
      },
      2: {
        background: "#5581c2",
        name: t("Report.sc_2"),
      },
      3: {
        background: "#21cc64",
        name: t("Report.sc_3"),
      },
      4: {
        background: "#ff0000",
        name: t("Report.sc_4"),
      },
    };
  }
  get getReportConversion() {
    return this.conversionData;
  }

  @Mutation
  [Mutations.CONVERSION_REPORT_DATA](Report) {
    this.conversionData = Report;
  }

  @Action
  [Actions.CONVERSION_REPORT]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.getData("/report/conversion")
        .then(({ data }) => {
          this.context.commit(Mutations.CONVERSION_REPORT_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.SEARCH_CONVERSION_REPORT](dataSearch) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/report/conversion", dataSearch)
        .then(({ data }) => {
          this.context.commit(Mutations.CONVERSION_REPORT_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  async [Actions.SEARCH_JOURNEY_CONVERSION_REPORT](dataSearch) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post(
        "/report/grouped-conversions-by-click",
        dataSearch
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
          return data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
          return false;
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
      return false;
    }
  }
}
