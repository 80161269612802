import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface CityList {
  errors: unknown;
  CityList: CityList;
  isAuthenticated: boolean;
}

@Module
export default class CityListModule extends VuexModule implements CityList {
  errors = {};
  CityList = {} as CityList;
  isAuthenticated = !!JwtService.getToken();

  get getCityListData() {
    return this.CityList;
  }

  @Mutation
  [Mutations.CITYLIST_DATA](CityList) {
    this.CityList = CityList;
  }

  @Action
  [Actions.CITYLIST](data) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/city-list", data)
        .then(({ data }) => {
          this.context.commit(Mutations.CITYLIST_DATA, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.PURGE_AUTH_OR_SET_ERROR, response.data);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }
}
